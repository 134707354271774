import React, { useState, useEffect } from "react";
import { FaFacebook, FaYoutube, FaInstagram, FaTwitter, FaUser } from "react-icons/fa";

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false); // Toggle for offcanvas menu
  const [isSearchOpen, setSearchOpen] = useState(false); // Toggle for search offcanvas
  const [isScrolled, setIsScrolled] = useState(false); // Check if the page is scrolled

  const toggleMenu = () => setMenuOpen(!isMenuOpen);
  const toggleSearch = () => setSearchOpen(!isSearchOpen);
  const isLoggedIn = Boolean(localStorage.getItem('access_token'));

  // Add scroll event listener to toggle sticky class
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true); // Show sticky navbar
      } else {
        setIsScrolled(false); // Hide sticky navbar
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={` wrapper bg-black text-white fixed top-0 left-0 w-full z-50
      }`}
    >
      <nav className="navbar navbar-expand-lg center-nav navbar-dark  caret-none">
        <div className="container xl:flex-row lg:flex-row !flex-nowrap items-center">
          {/* Logo */}
          <div className="navbar-brand w-full">
          <a href="/" className="text-2xl rounded-lg w-[40px] px-3 py-3 bg-black">
                  <span className="text-white shadow-2xl px-2 font-bold">eWeb</span>
                  <span className="text-black bg-yellow-700 font-bold text-md shadow-2xl p-2 rounded-md">Hub</span>
                </a>
          </div>

          {/* Offcanvas Menu */}
          <div
            className={`navbar-collapse offcanvas offcanvas-nav offcanvas-start ${
              isMenuOpen ? "show" : ""
            }`}
          >
            <div className="offcanvas-header xl:hidden lg:hidden flex items-center justify-between flex-row p-6">
            <h3 className="text-white xl:text-[1.5rem] !text-[calc(1.275rem_+_0.3vw)] !mb-0">
                <a href="/" className="text-2xl rounded-lg w-[40px] px-3 py-3 bg-black">
                  <span className="text-white shadow-2xl px-2 font-bold">eWeb</span>
                  <span className="text-black bg-yellow-700 font-bold text-md shadow-2xl p-2 rounded-md">Hub</span>
                </a>
              </h3>
              <button
                type="button"
                className="btn-close btn-close-white"
                onClick={toggleMenu}
                aria-label="Close"
              ></button>
            </div>

            <div className="offcanvas-body xl:!ml-auto lg:!ml-auto flex flex-col !h-full">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link !font-semibold !text-[.85rem] !tracking-[normal]" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link !font-semibold !text-[.85rem] !tracking-[normal]" href="/photo-contests">
                    Contests
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link !font-semibold !text-[.85rem] !tracking-[normal]" href="/privacy-policy">
                    Privacy
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link !font-semibold !text-[.85rem] !tracking-[normal]" href="/contact-us">
                    Contact us
                  </a>
                </li>
                {!isLoggedIn ? (
          <>
            <li className="nav-item">
              <a
                href="/login"
                className="nav-link bg-yellow-600 text-white py-2 px-6 m-2 rounded-lg hover:bg-yellow-900 hover:scale-105 transform transition-all duration-300 ease-in-out focus:outline-none"
              >
                Login
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link bg-yellow-600 text-white py-2 px-6 m-2 rounded-lg hover:bg-yellow-900 hover:scale-105 transform transition-all duration-300 ease-in-out focus:outline-none"
                href="/register"
              >
                Register
              </a>
            </li>
          </>
        ) : (
          <li className="nav-item flex items-center">
            <FaUser />
            <button
              onClick={() => {
                localStorage.removeItem('access_token'); // Clear the token
                window.location.reload(); // Reload to update UI
              }}
              className="nav-link bg-red-600 text-white py-2 px-6 mx-4 rounded-lg hover:bg-red-800 hover:scale-105 transform transition-all duration-300 ease-in-out focus:outline-none"
            >
              Logout
            </button>
          </li>
        )}

      <li className="nav-item mx-4 justify-end">
              <a
                href="/create-photo-contest"
                className="nav-link bg-green-600 text-white py-2 px-6 m-2 rounded-lg hover:bg-green-900 hover:scale-105 transform transition-all duration-300 ease-in-out focus:outline-none"
              >
                Create Contest
              </a>
            </li>
            <li className="nav-item">
                  <a className="nav-link !font-semibold !text-[.85rem] !tracking-[normal]" href="/my-resume">
                    My Resume
                  </a>
                </li>
              </ul>

              {/* Offcanvas Footer */}
              <div className="offcanvas-footer xl:hidden lg:hidden">
                <div>
                  <a href="mailto:first.last@email.com" className="link-inverse">
                    ewebhub01@gmail.com
                  </a>
                  <br />
                  <nav className="nav social social-white mt-4 text-xl">
                    <a href="#" className="text-blue-600 p-1">
                      <FaFacebook />
                    </a>
                    <a href="#" className="text-blue-900 p-1">
                      <FaTwitter />
                    </a>
                    <a href="#" className="text-pink-800 p-1">
                      <FaInstagram />
                    </a>
                    <a href="#" className="text-red-700 p-1">
                      <FaYoutube />
                    </a>
                  </nav>
                </div>
              </div>
              
            </div>
          </div>

          {/* Navbar Other */}
          <div className="navbar-other w-full !flex !ml-auto">
            <ul className="navbar-nav !flex-row !items-center !ml-auto">
              <li className="nav-item">
                <button className="nav-link" onClick={toggleSearch}>
                  <i className="uil uil-search"></i>
                </button>
              </li>
              <li className="nav-item xl:hidden lg:hidden">
                <button className="hamburger offcanvas-nav-btn" onClick={toggleMenu}>
                  <span></span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Search Offcanvas */}
      {isSearchOpen && (
        <div className="offcanvas offcanvas-top !bg-[#ffffff]" id="offcanvas-search">
          <div className="container flex flex-row py-6">
            <form className="search-form relative w-full">
              <input
                type="text"
                className="form-control"
                placeholder="Type keyword and hit enter"
              />
            </form>
            <button type="button" className="btn-close" onClick={toggleSearch}></button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
