import React from "react";

const Contact = () => {
  return (
    <section id="contact" className="py-20 bg-gray-100">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Contact Me</h2>
        <p className="text-center text-lg text-gray-700">
          Email: <a href="mailto:ewebhub01@gmail.com" className="text-indigo-500">ewebhub01@gmail.com</a>
        </p>
        
      </div>
    </section>
  );
};

export default Contact;
