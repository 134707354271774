import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable"; // Import the swipeable library

const sliderData = [
  {
    image: "/assets/img/photos/fc1.jpg",
    title: "Lucy & David",
    photos: "5 Photos",
    category: "Wedding",
    alt: "Lucy & David Wedding",
  },
  {
    image: "/assets/img/photos/fc2.jpg",
    title: "Stacy & Thomas",
    photos: "8 Photos",
    category: "Wedding",
    alt: "Stacy & Thomas Wedding",
  },
  {
    image: "/assets/img/photos/fc3.jpg",
    title: "Katherine & Jack",
    photos: "7 Photos",
    category: "Couples",
    alt: "Katherine & Jack",
  },
  {
    image: "/assets/img/photos/fc4.jpg",
    title: "Jolene & William",
    photos: "9 Photos",
    category: "Wedding",
    alt: "Jolene & William Wedding",
  },
  {
    image: "/assets/img/photos/fc5.jpg",
    title: "Jenn & Richard",
    photos: "6 Photos",
    category: "Engagement",
    alt: "Jenn & Richard Engagement",
  },
  {
    image: "/assets/img/photos/fc6.jpg",
    title: "Gloria & Leo",
    photos: "8 Photos",
    category: "Wedding",
    alt: "Gloria & Leo Wedding",
  },
  {
    image: "/assets/img/photos/fc7.jpg",
    title: "Grace & Finn",
    photos: "7 Photos",
    category: "Engagement",
    alt: "Grace & Finn Engagement",
  },
];

const SuccessStories = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const width = window.innerWidth;
      if (width >= 1024) setItemsPerPage(3); // Desktop
      else if (width >= 768) setItemsPerPage(2); // Tablet
      else setItemsPerPage(1); // Mobile
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);

    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + itemsPerPage) % sliderData.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - itemsPerPage + sliderData.length) % sliderData.length
    );
  };

  const visibleSlides = sliderData.slice(currentIndex, currentIndex + itemsPerPage);

  // Swipe handlers for mobile
  const swipeHandlers = useSwipeable({
    onSwipedLeft: nextSlide, // Swipe left to move to the next slide
    onSwipedRight: prevSlide, // Swipe right to move to the previous slide
    preventScrollOnSwipe: true,
    trackMouse: true, // Allow mouse swipe for desktop testing
  });

  return (
    <section className="wrapper bg-white">
      <div className="container mx-auto py-16">
        <div className="text-center mb-10">
          <h2 className="text-2xl font-semibold leading-tight">
            Join our photo contest and connect with models to capture creative, emotional moments. Showcase your talent and make lasting memories together!
          </h2>
        </div>

        <div {...swipeHandlers} className="relative overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {visibleSlides.map((slide, index) => (
              <div key={index} className="card shadow-md">
                <figure className="relative">
                  <img
                    className="w-full h-56 object-cover rounded-t-md"
                    src={slide.image}
                    alt={slide.alt}
                  />
                </figure>
                <div className="p-4 text-center">
                  <h3 className="text-lg font-bold">{slide.title}</h3>
                  <p className="text-gray-500 text-sm">{slide.photos}</p>
                  <p className="text-gray-500 text-sm">{slide.category}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-center mt-6">
            <button
              onClick={prevSlide}
              className="bg-yellow-800 text-white px-2 mx-2 py-2 rounded-full hover:bg-yellow-700"
            >
              Prev
            </button>
            <button
              onClick={nextSlide}
              className="bg-yellow-800 text-white px-2 py-2 rounded-full hover:bg-yellow-700"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SuccessStories;
