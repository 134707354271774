import React from "react";
import "tailwindcss/tailwind.css";
import { FaCameraRetro, FaPencilAlt, FaUpload, FaVoteYea, FaAward } from "react-icons/fa";

const ContestFeature = () => {
  return (
    <div className="container-fluid py-24 bg-gray-50">
      <div className="home-page-core-activities-area">
        <div className="flex flex-col lg:flex-row justify-between items-center bg-black">
          {/* Left Image Section */}
          <div className="md:w-1/2  relative rounded-sm mb-8 lg:mb-0">
            <img
              className="w-full h-2/4 object-cover rounded-sm "
              src="https://images.unsplash.com/photo-1713372402104-0ec604312179?q=80&w=1740"
              alt="activities"
            />
          </div>

          {/* Right Content Section */}
          <div className="md:w-1/2 px-6 lg:px-12 text-white">
            <div className="home-activities-area space-y-8">
              {/* Title */}
              <div className="text-center mb-8">
                <h2 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-black to-yellow-700">
                  Photo Contest Features
                </h2>
              </div>

              {/* Features Section */}
              <div className="space-y-6">
                <div className="single-activities hover:bg-yellow-100  hover:text-black transition-all rounded-lg p-4 shadow-lg">
                  <div className="media flex items-center space-x-4">
                    <div className="icon bg-yellow-700 p-4 rounded-full text-white ">
                      <FaCameraRetro />
                    </div>
                    <div className="media-body">
                      <h4 className="text-xl font-semibold">
                        Eligibility Rules
                      </h4>
                      <p>
                        Open to aspiring and professional photographers worldwide. Age restrictions and regional eligibility may apply.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="single-activities hover:bg-yellow-100  hover:text-black  transition-all rounded-lg p-4 shadow-lg">
                  <div className="media flex items-center space-x-4">
                    <div className="icon bg-yellow-700 p-4 rounded-full text-white">
                      <FaPencilAlt />
                    </div>
                    <div className="media-body">
                      <h4 className="text-xl font-semibold">
                        Photo Submission Guidelines
                      </h4>
                      <p>
                        Upload high-resolution JPEG or PNG files (up to 5MB each). Submit a maximum of three photos per contest.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="single-activities hover:bg-yellow-100  hover:text-black  transition-all rounded-lg p-4 shadow-lg">
                  <div className="media flex items-center space-x-4">
                    <div className="icon bg-yellow-700 p-4 rounded-full text-white">
                      <FaVoteYea />
                    </div>
                    <div className="media-body">
                      <h4 className="text-xl font-semibold">
                        Voting & Judging
                      </h4>
                      <p>
                        Public voting and expert panel judging ensure a fair evaluation. Limit one vote per person, per entry.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="single-activities hover:bg-yellow-100  hover:text-black  transition-all rounded-lg p-4 shadow-lg">
                  <div className="media flex items-center space-x-4">
                    <div className="icon bg-yellow-700 p-4 rounded-full text-white">
                      <FaUpload />
                    </div>
                    <div className="media-body">
                      <h4 className="text-xl font-semibold">
                        Categories
                      </h4>
                      <p>
                        Explore diverse categories like Fashion, Portraits, Nature, Editorial, and Creative Themed Competitions.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="single-activities hover:bg-yellow-100  hover:text-black  transition-all rounded-lg p-4 shadow-lg">
                  <div className="media flex items-center space-x-4">
                    <div className="icon bg-yellow-700 p-4 rounded-full text-white">
                      <FaAward />
                    </div>
                    <div className="media-body">
                      <h4 className="text-xl font-semibold">
                        Exciting Prizes
                      </h4>
                      <p>
                        Win cash prizes, recognition, and portfolio development opportunities. Prizes distributed securely and promptly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center mt-8 py-4">
                <button className="bg-yellow-600 text-white py-2 px-6 rounded-full shadow-lg hover:bg-yellow-700 transition duration-300">
                  Join the Contest
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestFeature;
