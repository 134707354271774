// actions/categoryAction.js
export const FETCH_CATEGORIES_REQUEST = "FETCH_CATEGORIES_REQUEST";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";

export const FETCH_COMPETITIONS_REQUEST = "FETCH_COMPETITIONS_REQUEST";
export const FETCH_COMPETITIONS_SUCCESS = "FETCH_COMPETITIONS_SUCCESS";
export const FETCH_COMPETITIONS_FAILURE = "FETCH_COMPETITIONS_FAILURE";

export const FETCH_COMPETITION_DETAIL_REQUEST = "FETCH_COMPETITION_DETAIL_REQUEST";
export const FETCH_COMPETITION_DETAIL_SUCCESS = "FETCH_COMPETITION_DETAIL_SUCCESS";
export const FETCH_COMPETITION_DETAIL_FAILURE = "FETCH_COMPETITION_DETAIL_FAILURE";

export const CREATE_COMPETITION_REQUEST = "CREATE_COMPETITION_REQUEST";
export const CREATE_COMPETITION_SUCCESS = "CREATE_COMPETITION_SUCCESS";
export const CREATE_COMPETITION_FAILURE = "CREATE_COMPETITION_FAILURE";

// Action creators
export const fetchCategoriesRequest = () => ({
  type: FETCH_CATEGORIES_REQUEST,
});

export const fetchCategoriesSuccess = (categories) => ({
  type: FETCH_CATEGORIES_SUCCESS,
  payload: categories,
});

export const fetchCategoriesFailure = (error) => ({
  type: FETCH_CATEGORIES_FAILURE,
  payload: error,
});

export const fetchCompetitionsRequest = () => ({
  type: FETCH_COMPETITIONS_REQUEST,
});

export const fetchCompetitionsSuccess = (competitions) => ({
  type: FETCH_COMPETITIONS_SUCCESS,
  payload: competitions,
});

export const fetchCompetitionsFailure = (error) => ({
  type: FETCH_COMPETITIONS_FAILURE,
  payload: error,
});

export const fetchCompetitionDetailRequest = (code) => ({
  type: FETCH_COMPETITION_DETAIL_REQUEST,
  payload: code,
});

export const fetchCompetitionDetailSuccess = (competitionDetail) => ({
  type: FETCH_COMPETITION_DETAIL_SUCCESS,
  payload: competitionDetail,
});

export const fetchCompetitionDetailFailure = (error) => ({
  type: FETCH_COMPETITION_DETAIL_FAILURE,
  payload: error,
});

export const createCompetitionRequest = (payload) => ({
  type: CREATE_COMPETITION_REQUEST,
  payload,
});

export const createCompetitionSuccess = (data) => ({
  type: CREATE_COMPETITION_SUCCESS,
  payload: data,
});

export const createCompetitionFailure = (error) => ({
  type: CREATE_COMPETITION_FAILURE,
  payload: error,
});