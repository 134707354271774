import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PhotoDetailContent from "../components/PhotoDetailContent";
import PrivacyPolicy from "../components/PrivacyPolicy";
import CancellationsAndRefunds from "../components/CancellationsAndRefunds";

const CancellationsAndRefundsPage = () => {
  return (
    <div className="bg-gray-100 text-gray-900">
      {/* Navbar */}

      {/* Header Section */}
      <Header />

      <main>
        {/* Slider Section */}
        <section className="">
          <CancellationsAndRefunds />
        </section>

       
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default CancellationsAndRefundsPage;
