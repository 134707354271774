import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-10">
      <div className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Logo and About Section */}
        <div>
          <div className="flex items-center mb-4">
            {/* <img
              src="/logo.png"
              alt="Photo Contest Logo"
              className="h-10 w-10 mr-2"
            /> */}
            <h3 className="text-white xl:text-[1.5rem] !text-[calc(1.275rem_+_0.3vw)] !mb-0">
                <a href="/" className="text-2xl rounded-lg w-[40px] px-3 py-3 bg-black">
                  <span className="text-white shadow-2xl px-2 font-bold">eWeb</span>
                  <span className="text-black bg-yellow-700 font-bold text-md shadow-2xl p-2 rounded-md">Hub</span>
                </a>
              </h3>
          </div>
          <p className="text-sm">
            Participate in exciting photo contests, showcase your talent, and
            win amazing prizes. Join a community of creative photographers and
            models.
          </p>
        </div>

        {/* Quick Links Section */}
        <div>
          <h4 className="text-lg font-semibold text-white mb-4">Quick Links</h4>
          <ul className="space-y-2 text-sm">
            <li>
              <Link
                to="/photo-contests"
                className="hover:text-gray-100 transition duration-300"
              >
                Competitions
              </Link>
            </li>
            <li>
              <Link
                to="/launch-soon"
                className="hover:text-gray-100 transition duration-300"
              >
                FAQs
              </Link>
            </li>
            <li>
              <Link
                to="/about-us"
                className="hover:text-gray-100 transition duration-300"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                className="hover:text-gray-100 transition duration-300"
              >
                Contact Us
              </Link>
            </li>
            <li>
              <Link
                to="/privacy-policy"
                className="hover:text-gray-100 transition duration-300"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to="/terms-and-conditions"
                className="hover:text-gray-100 transition duration-300"
              >
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link
                to="/cancellation-policies"
                className="hover:text-gray-100 transition duration-300"
              >
                Cancellation & Refunds
              </Link>
            </li>
          </ul>
        </div>

        {/* Contact Section */}
        <div>
          <h4 className="text-lg font-semibold text-white mb-4">Contact Us</h4>
          <ul className="space-y-2 text-sm">
            <li>Email: ewebhub01@gmail.com</li>
          </ul>
        </div>

        {/* Social Media Section */}
        <div>
          <h4 className="text-lg font-semibold text-white mb-4">Follow Us</h4>
          <div className="flex space-x-4">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-gray-100 transition duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M22 12c0-5.522-4.478-10-10-10S2 6.478 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.988h-2.54v-2.89h2.54V9.882c0-2.508 1.492-3.89 3.773-3.89 1.093 0 2.235.195 2.235.195v2.46h-1.258c-1.241 0-1.626.772-1.626 1.562v1.87h2.773l-.443 2.89h-2.33V21.878C18.343 21.128 22 16.991 22 12z" />
              </svg>
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-gray-100 transition duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M22.46 6.003c-.77.34-1.598.57-2.46.673a4.293 4.293 0 001.887-2.38 8.575 8.575 0 01-2.725 1.04 4.267 4.267 0 00-7.28 3.89 12.105 12.105 0 01-8.8-4.46 4.26 4.26 0 001.32 5.69 4.26 4.26 0 01-1.934-.537c-.046 2.068 1.448 3.917 3.567 4.333a4.285 4.285 0 01-1.93.073 4.27 4.27 0 003.986 2.967 8.553 8.553 0 01-5.3 1.828A12.063 12.063 0 007.29 21c7.89 0 12.21-6.54 12.21-12.21 0-.185-.004-.37-.012-.554A8.69 8.69 0 0022.46 6.003z" />
              </svg>
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-gray-100 transition duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M16 2H8a6 6 0 00-6 6v8a6 6 0 006 6h8a6 6 0 006-6V8a6 6 0 00-6-6zm4 14a4 4 0 01-4 4H8a4 4 0 01-4-4V8a4 4 0 014-4h8a4 4 0 014 4v8zm-7.5-8A4.5 4.5 0 108 10.5 4.5 4.5 0 0012.5 8zm0 7a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5zm5.5-5.5a1 1 0 111-1 1 1 0 01-1 1z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="mt-10 text-center text-sm text-gray-500">
        © {new Date().getFullYear()} eWebhub PhotoContest. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
