import React, { useState } from 'react';

const TestimonialComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Testimonial data
  const testimonials = [
    {
      quote: "“Capture the world through your lens and let your creativity speak! Join our photo contest and showcase your talent to the world.”",
      name: "John & Emily"
    },
    {
      quote: "“Submit your best shots and be a part of an exciting journey! Let the world see your art and creativity in every frame.”",
      name: "Sophia & Alex"
    },
    {
      quote: "“Our photo contest is your chance to shine! Capture the moments that matter and get a chance to win exciting prizes!”",
      name: "Anna & Michael"
    }
  ];

  // Handle dot click to change slide
  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <section
    className="wrapper image-wrapper bg-image bg-overlay text-white bg-no-repeat bg-[center_center] bg-cover relative z-0 !bg-fixed before:content-[''] before:block before:absolute before:z-[1] before:w-full before:h-full before:left-0 before:top-0 before:bg-[rgba(30,34,40,.5)]"
    style={{ backgroundImage: "url(/assets/img/photos/bg32.jpg)" }} 
  > <div className="container py-[4.5rem] xl:!py-[7rem] lg:!py-[7rem] md:!py-[7rem] !text-center">
        <div className="swiper-container dots-light dots-closer !mb-6 relative z-10">
          <div className="swiper-wrapper ">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className={`swiper-slide ${index === currentSlide ? 'block' : 'hidden'} transition-all duration-1000 ease-in-out`}
              >
                <blockquote className="border-0 text-[1.2rem] !mb-0 m-[0_0_1rem] !leading-[1.7]   font-medium">
                  <p className='lg:px-60'>{testimonial.quote}</p>
                  <div className="flex items-center text-left justify-center">
                    <div className="info p-0">
                      <h6 className="mb-0 text-white md:px-10 !text-[.9rem] !tracking-[normal] font-semibold">{testimonial.name}</h6>
                    </div>
                  </div>
                </blockquote>
              </div>
            ))}
          </div>
        </div>

        {/* Dot Navigation */}
        <div className="dot-navigation flex justify-center mt-4">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => handleDotClick(index)}
              className={`dot ${index === currentSlide ? 'bg-white' : 'bg-gray-500'} w-3 h-3 mx-2 rounded-full transition-all duration-300`}
            ></button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialComponent;
