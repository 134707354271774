// redux/sagas/photoSaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
import { uploadPhotoRequest, uploadPhotoSuccess, uploadPhotoFailure, UPLOAD_PHOTO_REQUEST } from '../actions/photoActions';
import httpCommon from '../../services/httpCommon';  // Make sure to import your API setup

// API call to upload the photo
const uploadPhoto = async (data) => {
    // Retrieve the token from localStorage (or wherever it's stored)
    const token = localStorage.getItem('access_token');
  
    try {
      // Make the POST request with the Bearer token
      const response = await httpCommon.post('/api/upload-photo/', data, {
        headers: {
        'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error uploading photo:', error);
      throw error; // Handle the error appropriately
    }
  };

// Worker Saga to handle photo upload
function* handleUploadPhoto(action) {
  try {
    const photoData = yield call(uploadPhoto, action.payload);
    yield put(uploadPhotoSuccess(photoData));
  } catch (error) {
    yield put(uploadPhotoFailure(error.response?.data ||  error.message)); 
  }
}

// Watcher Saga
export function* watchPhotoUpload() {
  yield takeLatest(UPLOAD_PHOTO_REQUEST, handleUploadPhoto);
}
