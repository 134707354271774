import React, { useState } from "react";

// Function to calculate the compatibility score based on names
const calculateCompatibility = (name1, name2) => {
  // Convert names to lowercase for comparison
  const name1Lower = name1.toLowerCase();
  const name2Lower = name2.toLowerCase();

  // Count common characters between names
  let commonLetters = 0;
  const uniqueChars = new Set([...name1Lower, ...name2Lower]);
  uniqueChars.forEach(char => {
    if (name1Lower.includes(char) && name2Lower.includes(char)) {
      commonLetters++;
    }
  });

  // Calculate the score based on common letters and name length
  const nameLengthScore = (name1.length + name2.length) / 2;
  const compatibilityScore = Math.min(100, (commonLetters * 10) + nameLengthScore);

  return Math.floor(compatibilityScore);
};

const LoveCalculator = () => {
  const [loverName, setLoverName] = useState("");
  const [crushName, setCrushName] = useState("");
  const [loveScore, setLoveScore] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  const [randomScore, setRandomScore] = useState(0);

  // Function to simulate random number change during calculation
  const simulateRandomChange = (finalScore) => {
    setIsCalculating(true);
    let tempScore = 0;

    // Simulate random increase/decrease for 2 seconds
    const interval = setInterval(() => {
      tempScore = Math.floor(Math.random() * 100) + 1; // Random number between 1 and 100
      setRandomScore(tempScore); // Update the random score on the screen

      // After 2 seconds, stop the simulation and set the final score
      if (tempScore === finalScore) {
        clearInterval(interval);
        setLoveScore(finalScore); // Set the final score
        setIsCalculating(false);
      }
    }, 50); // Update every 50ms (rapid fluctuation)
  };

  // Start the calculation process
  const startCalculation = () => {
    if (loverName && crushName) {
      const finalScore = calculateCompatibility(loverName, crushName);
      simulateRandomChange(finalScore);
    }
  };

  // Flamingo color gradient effect
  const flameStyle = {
    
    animation: "flame 2s ease-in-out infinite"
  };

  return (
    <div className="w-full flex justify-center items-center py-10 text-yellow-500">
      <div className="container mx-auto">
      <div className="flex flex-wrap mx-[-15px]">
            <div className="lg:w-9/12 xl:w-8/12 xxl:w-7/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto !text-center">
              <i className="icn-flower text-[#7cb798] xl:!text-[1.5rem] text-[calc(1.275rem_+_0.3vw)] opacity-25 before:content-['\e907']"></i>
              <h2 className="xl:text-[1.7rem] text-[calc(1.295rem_+_0.54vw)] !leading-[1.25] font-semibold tracking-[normal] !text-center !mt-2 mb-2">
              Welcome to the <strong className="text-yellow-600">Flamingo Love Calculator</strong>
              
              </h2>
              <p className="text-gray-700 text-md ">
            Wondering how compatible you are with your crush? Let the Flamingo flames tell you!
          </p>
             
            </div>
          </div>
       

        <div className="love-calculater w-full flex flex-col items-center justify-center">
        <div className="love-result text-center mb-4 py-8">
            {isCalculating ? (
              <div className="loading-spinner mb-4">
                {/* Show the random score fluctuating */}
                <p className="text-4xl text-pink-500 animate-pulse">{randomScore}%</p>
              </div>
            ) : loveScore !== null ? (
              <div className="relative mb-4 animate-bounce">
                {/* Heart SVG with percentage inside */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  className="w-48 h-48 text-red-600 absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="5"
                    d="M50 85s30-23 30-37C80 38 65 24 50 35 35 24 20 38 20 48c0 14 30 37 30 37z"
                  />
                </svg>
                <h2 className="absolute inset-0 flex justify-center items-center text-4xl font-bold text-red-500 ">
                  {loveScore}%
                </h2>
              </div>
            ) : null}
          </div>

          <div className="love-calculater-box w-full flex flex-col items-center">
            <div className="enter-name mb-4 w-1/2">
              <label htmlFor="nameInput1" className="block text-sm font-medium text-yellow-600">
                Your Name
              </label>
              <input
                type="text"
                id="nameInput1"
                className="mt-1 w-full p-2 border-2 border-yellow-300 rounded-md bg-white"
                placeholder="Full Name"
                value={loverName}
                onChange={(e) => setLoverName(e.target.value)}
              />
            </div>

            <div className="enter-name mb-6 w-1/2 ">
              <label htmlFor="nameInput2" className="block text-sm font-medium text-yellow-600">
                Your Crush's Name
              </label>
              <input
                type="text"
                id="nameInput2"
                className="mt-1 w-full p-2 border-2 border-yellow-300 rounded-md bg-white"
                placeholder="Crush Name"
                value={crushName}
                onChange={(e) => setCrushName(e.target.value)}
              />
            </div>

            <div className="calculat-love w-full flex justify-center">
              <button
                className="bg-yellow-600 text-white p-3 rounded-md hover:bg-yellow-700 transition-all ease-in-out duration-300"
                onClick={startCalculation}
              >
                Calculate Love
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoveCalculator;
