import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import httpCommon from "../services/httpCommon";

const VerifyOtpPage = () => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(""); // For error messages
  const [success, setSuccess] = useState(""); // For success messages

  const location = useLocation(); // Access the location object to get passed state
  const navigate = useNavigate(); // Initialize the navigate function

  const { email } = location.state || {}; // Destructure email from the passed state

  const handleChange = (e) => {
    setOtp(e.target.value);
    setError(""); // Clear errors on input change
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors
    setSuccess(""); // Clear previous success message

    if (!otp) {
      setError("OTP is required.");
      return;
    }

    try {
      const response = await httpCommon.post("/api/verify-otp/", { otp, email });

      // Store access and refresh tokens in localStorage
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("refresh_token", response.data.refresh_token);

      setSuccess("OTP verified successfully!");

      // Redirect to home page after successful OTP verification
      navigate("/contests"); // Replace '/home' with the actual path of your home page

    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message || "Failed to verify OTP.");
      } else {
        setError("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-yellow-700 to-pink-500 p-4">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl w-full">
        <h2 className="text-2xl font-bold mb-4 text-center text-yellow-700">
          Verify OTP for {email}
        </h2>
        {error && (
          <div className="bg-red-100 text-red-700 p-3 rounded-md mb-4">
            {error}
          </div>
        )}
        {success && (
          <div className="bg-green-100 text-green-700 p-3 rounded-md mb-4">
            {success}
          </div>
        )}
        <form onSubmit={handleVerifyOtp} className="space-y-4">
          <span className="text-green-700">OTP sent to your email- {email}</span>
          <div>
            <label className="block text-sm font-medium text-gray-700">OTP</label>
            <input
              type="text"
              name="otp"
              value={otp}
              onChange={handleChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              placeholder="Enter OTP"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-yellow-700 text-white py-2 rounded-lg hover:bg-yellow-800"
          >
            Verify OTP
          </button>
        </form>
      </div>
    </div>
  );
};

export default VerifyOtpPage;
