import { FETCH_PHOTO_DETAIL_FAILURE, FETCH_PHOTO_DETAIL_REQUEST, FETCH_PHOTO_DETAIL_SUCCESS } from "../actions/photoDetailActions";

const initialState = {
    photoDetails: {},
    loading: false,
    error: null,
  };
  
  export const photoDetailReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PHOTO_DETAIL_REQUEST:
        return { ...state, loading: true };
      case FETCH_PHOTO_DETAIL_SUCCESS:
        return { ...state, loading: false, photoDetails: action.payload };
      case FETCH_PHOTO_DETAIL_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  