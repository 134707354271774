import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CompetitionForm from "../components/CompetitionForm";

const CompetitionPage = () => {
  return (
    <div className="bg-gray-100 text-gray-900 ">
      {/* Navbar */}

      {/* Header Section */}
      <Header />

      <main>
        {/* Slider Section */}
        <section className="mt-20">
          <CompetitionForm />
        </section>

        

       
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default CompetitionPage;
