import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { REGISTER_REQUEST, registerSuccess, registerFailure } from '../actions/registerActions';

// API function for registration
const registerAPI = (data) => {
  return axios.post('/api/register', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Worker Saga
function* registerSaga(action) {
  try {
    yield call(registerAPI, action.payload);
    yield put(registerSuccess());
    console.log('Registration successful');
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Registration failed';
    yield put(registerFailure(errorMessage));
  }
}

// Watcher Saga
function* watchRegisterSaga() {
  yield takeLatest(REGISTER_REQUEST, registerSaga);
}

export default watchRegisterSaga;
