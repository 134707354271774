import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_COMPETITION_DETAIL_REQUEST,
  fetchCompetitionDetailSuccess,
  fetchCompetitionDetailFailure,
} from "../actions/categoryAction";
import httpCommon from "../../services/httpCommon";

// API call to fetch competition detail
const fetchCompetitionDetailFromAPI = async (code) => {
  const response = await httpCommon.get(`/api/competition/${code}/`);
  return response.data;
};

// Saga to handle fetching competition detail
function* fetchCompetitionDetail(action) {
  try {
    const data = yield call(fetchCompetitionDetailFromAPI, action.payload);
    yield put(fetchCompetitionDetailSuccess(data));
  } catch (error) {
    yield put(fetchCompetitionDetailFailure(error.message));
  }
}

// Root saga
export function* competitionDetailSaga() {
  yield takeLatest(FETCH_COMPETITION_DETAIL_REQUEST, fetchCompetitionDetail);
}