// photoReducer.js
import {
    LIKE_PHOTO_REQUEST,
    LIKE_PHOTO_SUCCESS,
    LIKE_PHOTO_FAILURE,
  } from  "../actions/photoActions";
  
  const initialState = {
    photosData: [],
    loading: false,
    error: null,
  };
  
  export const likeReducer = (state = initialState, action) => {
    switch (action.type) {
      case LIKE_PHOTO_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case LIKE_PHOTO_SUCCESS:
        return {
          ...state,
          loading: false,
          photosData: state.photosData.map((photo) =>
            photo.id === action.payload.photoId
              ? { ...photo, is_liked: action.payload.likeValue }
              : photo
          ),
        };
  
      case LIKE_PHOTO_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  