import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Slider from "../components/Slider";
import BottomSlider from "../components/BottomSlider";
import About from "../components/About";
import Portfolio from "../components/Portfolio";
import ContestFeature from "../components/ContestFeature";
import RunningContest from "../components/RunningContest";
import ProjectActivation from "../components/ProjectActivation";
import Skills from "../components/Skills";
import Projects from "../components/Projects";
import Experience from "../components/Experience";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import LoveCalculator from "../components/LoveCalculator";
import AboutSection from "../components/AboutSection";
import SuccessStories from "../components/SuccessStories";
import BlogSection from "../components/BlogSection";
import TestimonialComponent from "../components/TestimonialComponent";

const HomePage = () => {
  return (
    <div className="bg-gray-100 text-gray-900">
      {/* Navbar */}

      {/* Header Section */}
      <Header />

      <main>
        {/* Slider Section */}
        <section className="">
          <Slider />
        </section>

        {/* Bottom Slider Section */}
        <section className="">
          <BottomSlider />
        </section>

        {/* About Section */}
        <section id="about" className=" bg-white">
          <About />
        </section>
        <section id="portfolio" className=" bg-gray-50">
          <LoveCalculator />
        </section>
        <section id="project-activation" className=" bg-white">
          <SuccessStories />
        </section>

        {/* Portfolio Section */}
        <section id="portfolio" className=" bg-gray-50">
          <Portfolio />
        </section>
        <TestimonialComponent />

        {/* Contest Feature Section */}
        <section id="contest-feature" className=" bg-white">
          <AboutSection />
        </section>
        <section id="project-activation" className=" bg-white">
          <BlogSection />
        </section>

        
        {/* Running Contests Section */}
        

        {/* Project Activation Section */}
        <section id="project-activation" className=" bg-white">
          <ProjectActivation />
        </section>

       
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HomePage;
