import { call, put, takeLatest } from "redux-saga/effects";
import {
  FETCH_CATEGORIES_REQUEST,
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
  FETCH_COMPETITIONS_REQUEST,
  fetchCompetitionsSuccess,
  fetchCompetitionsFailure,
  createCompetitionSuccess,
  createCompetitionFailure,
  CREATE_COMPETITION_REQUEST,
} from "../actions/categoryAction";
import httpCommon from "../../services/httpCommon";

// Mock API calls
const fetchCategories = async () => {
  try {
    const response = await httpCommon.get(`/api/categories/`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch categories');
  }
};

const fetchCompetitions = async () => {
  try {
    const response = await httpCommon.get(`/api/competitions/`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch competitions');
  }
};



function* handleFetchCategories() {
  try {
    const categories = yield call(fetchCategories);
    yield put(fetchCategoriesSuccess(categories));
  } catch (error) {
    yield put(fetchCategoriesFailure(error.message));
  }
}

function* handleFetchCompetitions() {
  try {
    const competitions = yield call(fetchCompetitions);
    yield put(fetchCompetitionsSuccess(competitions));
  } catch (error) {
    yield put(fetchCompetitionsFailure(error.message));
  }
}
function* createCompetitionSaga(action) {
  try {
    const { competitionData, prizeTiers } = action.payload;

    const formData = new FormData();
    Object.keys(competitionData).forEach((key) => {
      formData.append(key, competitionData[key]);
    });
    formData.append("prize_tiers", JSON.stringify(prizeTiers));

    const response = yield call(() =>
      httpCommon.post("/api/competitions/create/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Add Bearer token from localStorage
        },
      })
    );

    yield put(createCompetitionSuccess(response.data));
  } catch (error) {
    yield put(createCompetitionFailure(error.message));
  }
}


export function* categoriesSaga() {
  yield takeLatest(FETCH_CATEGORIES_REQUEST, handleFetchCategories);
  yield takeLatest(FETCH_COMPETITIONS_REQUEST, handleFetchCompetitions);
  yield takeLatest(CREATE_COMPETITION_REQUEST, createCompetitionSaga);
}
