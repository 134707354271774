// photoSagas.js
import { call, put, takeLatest } from "redux-saga/effects";
import {
  LIKE_PHOTO_REQUEST,
  LIKE_PHOTO_SUCCESS,
  LIKE_PHOTO_FAILURE,
} from "../actions/photoActions";
import httpCommon from "../../services/httpCommon";
// Your Axios instance

function* likePhotoSaga(action) {
    const { photoId, likeValue } = action.payload;
  
    try {
      // Retrieve the token from localStorage
      const accessToken = localStorage.getItem("access_token");
  
      // Check if the token exists
      if (!accessToken) {
        throw new Error("User is not authenticated.");
      }
  
      // Add the Bearer token to the headers dynamically
      const response = yield call(httpCommon.post, `/api/vote/`, {
        photo: photoId,
        vote_value: likeValue,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Add the token here
        },
      });
  
      // If the request is successful
      if (response.status === 200) {
        yield put({ type: LIKE_PHOTO_SUCCESS, payload: { photoId, likeValue } });
      }
    } catch (error) {
      yield put({
        type: LIKE_PHOTO_FAILURE,
        payload: error.response?.data?.message || "Failed to like/unlike the photo.",
      });
    }
  }

export default function* likeSagas() {
  yield takeLatest(LIKE_PHOTO_REQUEST, likePhotoSaga);
}
