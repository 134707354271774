import React from "react";

const Experience = () => {
  const experiences = [
    {
      company: "Tech Mahindra",
      position: "Software Engineer",
      duration: "September 2022 – Present",
      description:
        "Worked on Dynamic Chatbot Creation Platform using LangChain and NLP.",
    },
    {
      company: "Dish TV India Limited",
      position: "Software Engineer",
      duration: "April 2021 – September 2022",
      description: "Developed an Anonymous Safe Place platform.",
    },
    // Add more experiences here...
  ];

  return (
    <section id="experience" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Experience</h2>
        <div className="space-y-6">
          {experiences.map((exp, index) => (
            <div
              key={index}
              className="p-6 bg-gray-100 rounded-lg shadow hover:shadow-lg transition"
            >
              <h3 className="text-xl font-bold">{exp.company}</h3>
              <p className="text-gray-600">
                {exp.position} • {exp.duration}
              </p>
              <p className="mt-2 text-gray-700">{exp.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experience;
