import React, { useState } from "react";
import "tailwindcss/tailwind.css";

const RunningContest = () => {
  // Data for the slides
  const contestData = [
    {
      image: "https://rstheme.com/products/html/shooter/shooter-html/images/about/3.jpg",
      title: "A way of feeling",
      contestName: "Professional Photo Grapher Contest",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    },
    {
      image: "https://rstheme.com/products/html/shooter/shooter-html/images/about/2.jpg",
      title: "Meet Bird Eye View",
      contestName: "New Photo Grapher Contest",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    },
    {
      image: "https://rstheme.com/products/html/shooter/shooter-html/images/about/1.jpg",
      title: "Skyline Photography",
      contestName: "Skyline Photography Contest",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    },
    {
      image: "https://rstheme.com/products/html/shooter/shooter-html/images/about/3.jpg",
      title: "Night Photography",
      contestName: "Night Photography Contest",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    },
    {
      image: "https://rstheme.com/products/html/shooter/shooter-html/images/about/1.jpg",
      title: "Urban Landscape",
      contestName: "Urban Photography Contest",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    },
  ];


  // State to track the current slide
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to go to the next slide
  const nextSlide = () => {
    if (currentIndex < contestData.length - 3) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="home-single-contest gray-bg pt-24 pb-24">
      <div className="home-single single-photo-contest-area">
        <div className="container mx-auto">
          <div className="section-title text-center mb-8">
            <h2 className="text-3xl font-bold">
              Our Running <span className="text-yellow-700">Contests</span>
            </h2>
            <p className="text-gray-600">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mihi vero, inquit, placet agi subtilius et, ut ipse dixisti, pressius.
            </p>
          </div>
          <div className="about-content">
            <h2 className="text-2xl font-bold text-center mb-8">Running Contests</h2>
            <div className="relative">
              {/* Slider Buttons (Left) */}
              <button
                onClick={prevSlide}
                className="absolute top-0 left-0 transform translate-y-1/2 bg-black text-white p-2 rounded-full"
              >
                &lt;
              </button>

              {/* Slider Buttons (Right) */}
              <button
                onClick={nextSlide}
                className="absolute top-0 right-0 transform translate-y-1/2 bg-black text-white p-2 rounded-full"
              >
                &gt;
              </button>

              <div className="grid grid-cols-3 gap-4">
                {contestData.slice(currentIndex, currentIndex + 3).map((contest, index) => (
                  <div key={index} className="item bg-white p-4 shadow-lg rounded-lg">
                    <div className="about-image relative">
                      <img
                        className="w-full h-64 object-cover rounded-lg"
                        src={contest.image}
                        alt={contest.title}
                      />
                      <div className="absolute bottom-4 left-4 bg-black bg-opacity-50 text-white p-2 rounded-lg">
                        <h4>
                          <a href={contest.registerLink} className="text-white">
                            {contest.title}
                          </a>
                        </h4>
                      </div>
                    </div>
                    <div className="about-text mt-4">
                      <h3 className="text-xl font-semibold">
                        <a href={contest.registerLink}>{contest.contestName}</a>
                      </h3>
                      <p className="text-gray-600">{contest.description}</p>
                    </div>
                    
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RunningContest;
