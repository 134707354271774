import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import httpCommon from "../services/httpCommon";
import Header from "../components/Header";
import axios from "axios";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const SignUpPage = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    password: "",
    confirm_password: "",
    title: "Mr",
    nationality: "",
    residence_country: "",
    role: "model",
  });
  const [error, setError] = useState(""); // For error messages
  const [success, setSuccess] = useState(""); // For success messages
  const [loading, setLoading] = useState(false); // For loading state
  const [countries, setCountries] = useState([]); // Country list
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  // Fetch country list on component mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const countryList = response.data.map((country) => ({
          name: country.name.common,
        }));
        setCountries(countryList);
      } catch (error) {
        console.error("Failed to fetch countries:", error);
      }
    };
  
    // Fetch countries only if the countries list is empty
    if (countries.length === 0) {
      fetchCountries();
    }
  }, [countries]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(""); // Clear errors on input change
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, mobile_number: value });
    setError(""); // Clear errors on input change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors
    setSuccess(""); // Clear previous success message
    setLoading(true); // Set loading state to true

    if (formData.password !== formData.confirm_password) {
      setError("Passwords do not match.");
      setLoading(false); // Reset loading state
      return;
    }

    try {
      const response = await httpCommon.post("/api/register/", formData);
      setSuccess("Registration successful! Please verify your OTP.");

      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        mobile_number: "",
        password: "",
        confirm_password: "",
        title: "Mr",
        nationality: "",
        residence_country: "",
        role: "model",
      });

      // Assuming OTP is sent to the email
      const otp = "123456"; // Example OTP

      // Navigate to the VerifyOtpPage
      navigate("/verify-otp", {
        state: { email: formData.email, otp: otp },
      });
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.message || "Failed to register.");
      } else {
        setError("Something went wrong. Please try again.");
      }
    } finally {
      setLoading(false); // Reset loading state after request completion
    }
  };

  return (
    <section>
      <Header />
      <div className="min-h-screen mt-10 flex items-center justify-center bg-gradient-to-r from-black to-yellow-900 p-4">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl w-full">
          <h2 className="text-2xl font-bold mb-4 text-center text-yellow-700">
            Register
          </h2>
          {error && (
            <div className="bg-red-100 text-red-700 p-3 rounded-md mb-4">
              {error}
            </div>
          )}
          {success && (
            <div className="bg-green-100 text-green-700 p-3 rounded-md mb-4">
              {success}
            </div>
          )}
          {loading && (
            <div className="text-center py-4">
              <div className="spinner-border text-yellow-700" role="status">
                <span className="sr-only">Loading....</span>
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <select
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                >
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Dr">Dr</option>
                  <option value="Prof">Prof</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  First Name
                </label>
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                  placeholder="Enter your first name"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                  placeholder="Enter your last name"
                  required
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Mobile Number
                </label>
                <PhoneInput
                  international
                  defaultCountry="IN"
                  value={formData.mobile_number}
                  onChange={handlePhoneChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                  placeholder="Enter your mobile number"
                  required
                />
              </div>
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                  placeholder="Enter your password"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="confirm_password"
                  value={formData.confirm_password}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                  placeholder="Re-enter your password"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Role
                </label>
                <select
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                >
                  <option value="model">Model</option>
                  <option value="photographer">Photographer</option>
                  <option value="host">Host</option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nationality
                </label>
                <select
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                >
                  <option value="">Select Nationality</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Residence Country
                </label>
                <select
                  name="residence_country"
                  value={formData.residence_country}
                  onChange={handleChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded-md"
                >
                  <option value="">Select Residence Country</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-yellow-700 text-white py-2 rounded-lg hover:bg-yellow-800"
              disabled={loading} // Disable button while loading
            >
              {loading ? "Registering..." : "Register"}
            </button>
          </form>

          <div className="mt-4 text-center">
            <p>
              Already registered?{" "}
              <Link
                to="/login"
                className="text-yellow-700 hover:underline font-semibold"
              >
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUpPage;
