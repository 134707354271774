import React, { useState } from 'react';

const FAQComponent = () => {
  const faqData = [
    {
      id: 1,
      name: "Submission Guidelines",
      description: "Learn about the submission rules for the contest.",
      faqs: [
        {
          id: 1,
          question: "How do I submit my entry?",
          answer: "You can submit your entry via the contest portal. Make sure to follow the size requirements.",
        },
        {
          id: 2,
          question: "Are there any format restrictions?",
          answer: "Yes, please submit your entry in MP4 format with a maximum file size of 100MB.",
        },
      ],
    },
    {
      id: 2,
      name: "Voting Rules",
      description: "Understand the rules around voting in the contest.",
      faqs: [
        {
          id: 3,
          question: "Who can vote?",
          answer: "Only registered users who have submitted their entries are allowed to vote.",
        },
        {
          id: 4,
          question: "Can I change my vote?",
          answer: "No, once a vote is cast, it cannot be changed.",
        },
      ],
    },
  ];

  const [expandedCategory, setExpandedCategory] = useState(null);

  const toggleCategory = (id) => {
    setExpandedCategory(expandedCategory === id ? null : id);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center text-yellow-700 mb-6">Frequently Asked Questions</h1>
      {faqData.map((category) => (
        <div key={category.id} className="mb-6">
          <div
            className="bg-yellow-100 p-4 rounded-lg cursor-pointer shadow-md"
            onClick={() => toggleCategory(category.id)}
          >
            <h2 className="text-xl font-semibold text-yellow-800">{category.name}</h2>
            {expandedCategory === category.id && (
              <p className="mt-2 text-gray-600">{category.description}</p>
            )}
          </div>

          {expandedCategory === category.id && (
            <div className="mt-4 space-y-4">
              {category.faqs.map((faq) => (
                <div key={faq.id} className="bg-white p-4 rounded-lg shadow-md">
                  <h3 className="text-lg font-semibold text-yellow-600">{faq.question}</h3>
                  <p className="mt-2 text-gray-700">{faq.answer}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQComponent;
