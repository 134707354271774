import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import PhotoListComponent from '../components/PhotoListComponent';
import { fetchCompetitionDetailRequest } from '../redux/actions/categoryAction';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FaFacebook, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const ContestDetailPage = () => {
  const dispatch = useDispatch();
  const { code: contestId } = useParams();
  const { competitionDetail } = useSelector((state) => state.competition);
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    dispatch(fetchCompetitionDetailRequest(contestId));
  }, [contestId, dispatch]);

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  useEffect(() => {
    if (competitionDetail && competitionDetail.end_date) {
      const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const endDate = new Date(competitionDetail.end_date).getTime();
        const distance = endDate - now;

        if (distance > 0) {
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hrs = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const min = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const sec = Math.floor((distance % (1000 * 60)) / 1000);
          setTimeLeft({ days, hrs, min, sec });
        } else {
          setTimeLeft({ days: 0, hrs: 0, min: 0, sec: 0 });
        }
      };

      calculateTimeLeft();
      const interval = setInterval(calculateTimeLeft, 1000);
      return () => clearInterval(interval);
    }
  }, [competitionDetail]);

  // Ensure competitionDetail is available before rendering
  if (!competitionDetail) return <div>No contest data available</div>;

  return (
    <>
      {/* Only render Helmet once competitionDetail is available */}
      {competitionDetail && competitionDetail.name && (
        <Helmet>
          <title>{`Join the Photo Contest 2025 | ${competitionDetail.name}`}</title>
          <meta
            name="description"
            content={competitionDetail.description || 'Submit your best photography and win exciting prizes!'}
          />
          <meta property="og:title" content={competitionDetail.name} />
          <meta
            property="og:description"
            content={competitionDetail.description || 'Submit your best photography and win exciting prizes!'}
          />
          <meta property="og:image" content={competitionDetail.image || 'https://ewebhub.com/default-image.jpg'} />
          <meta property="og:url" content={currentUrl} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={competitionDetail.name} />
          <meta
            name="twitter:description"
            content={competitionDetail.description || 'Submit your best photography and win exciting prizes!'}
          />
          <meta name="twitter:image" content={competitionDetail.image || 'https://ewebhub.com/default-image.jpg'} />
          <link rel="canonical" href={currentUrl} />
        </Helmet>
      )}

      <div className="mx-auto px-4 py-8">
        <Header />
        <div className="mx-auto sm:px-4 py-8 mt-10">
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <img
              src={competitionDetail.image}
              alt={competitionDetail.name || 'Contest Image'}
              className="w-full h-64 object-cover"
            />
            <div className="p-2 sm:p-6">
              <h1 className="text-3xl font-semibold text-yellow-800">{competitionDetail.name || 'Contest Name'}</h1>
              <p className="mt-4 text-sm md:text-md text-gray-600">{competitionDetail.description || 'No description available'}</p>
              <div className="mt-6 flex justify-between items-center text-sm text-gray-500">
                <span>
                  Category: <strong className="text-yellow-600">{competitionDetail.category?.name || 'Unknown'}</strong>
                </span>
                <span>
                  Start Date: <strong>{new Date(competitionDetail.start_date).toLocaleDateString() || 'N/A'}</strong>
                </span>
              </div>
              <div className="flex justify-between items-center mt-2 text-sm text-gray-500">
                <span>
                  End Date: <strong>{new Date(competitionDetail.end_date).toLocaleDateString() || 'N/A'}</strong>
                </span>
              </div>

              {/* Timer Section */}
              <div className="flex flex-wrap justify-center mt-8 space-x-1 md:space-x-4">
                {Object.entries(timeLeft).map(([unit, value]) => (
                  <div key={unit} className="bg-white bg-opacity-10 p-4 rounded-lg shadow-md sm:block">
                    <p className="text-sm md:text-3xl font-bold text-yellow-800">{value}</p>
                    <p className="text-sm text-gray-500">{unit}</p>
                  </div>
                ))}
              </div>

              {/* Participate Button */}
              <div className="mt-6 flex justify-center">
                <a href={`/submit-new-entry/${competitionDetail.code}/upload/`} className="text-white">
                  <button className="bg-yellow-800 text-white px-6 py-3 rounded-lg hover:bg-yellow-700">
                    Participate Now
                  </button>
                </a>
              </div>

              {/* Social Media Share Buttons */}
              <div className="mt-6 flex justify-center space-x-4">
                {/* Facebook Share */}
                <FacebookShareButton
                  url={`https://ewebhub.com/contest-detail/${competitionDetail?.code}`}
                  quote={encodeURIComponent(`🔥 Join the ${competitionDetail?.name} competition now! 🏆 Show your talent and participate today. Click the link below 👇`)}
                  hashtag={`#eWebHubPhotoContest${competitionDetail?.code}`}
                >
                  <FaFacebook className="text-blue-600 text-3xl hover:opacity-75 cursor-pointer" />
                </FacebookShareButton>

                {/* Twitter Share */}
                <TwitterShareButton
                  url={`https://ewebhub.com/contest-detail/${competitionDetail?.code}`}
                  title={`🚀 Exciting contest alert! Participate in ${competitionDetail?.name} 🏅. Don't miss your chance! Click below: `}
                  hashtags={[`eWebHubPhotoContest${competitionDetail?.code}`]}
                >
                  <FaTwitter className="text-blue-400 text-3xl hover:opacity-75 cursor-pointer" />
                </TwitterShareButton>

                {/* WhatsApp Share */}
                <WhatsappShareButton
                  url={`https://ewebhub.com/contest-detail/${competitionDetail?.code}`}
                  title={`🎉 Join the ${competitionDetail?.name} competition now! 🏆 Submit your best photos and win amazing prizes. Participate here: `}
                  separator=" "
                >
                  <FaWhatsapp className="text-green-500 text-3xl hover:opacity-75 cursor-pointer" />
                </WhatsappShareButton>
              </div>
            </div>
          </div>

          <PhotoListComponent competitionCode={competitionDetail.code} />
        </div>
      </div>
    </>
  );
};

export default ContestDetailPage;
