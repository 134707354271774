// src/utils/api.js
import axios from 'axios';

// Create an Axios instance with a base URL
const httpCommon = axios.create({
  baseURL: 'https://contest.ewebhub.com', // Replace with your API's base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// You can add interceptors here if needed for automatic token refresh etc.

export default httpCommon;
