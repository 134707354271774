import React, { useState, useEffect } from "react";

const LaunchSoonPage = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const targetDate = new Date("2025-01-16T00:00:00Z");
    const difference = targetDate - new Date();
    if (difference <= 0) return null;

    const timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / (1000 * 60)) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };

    return timeLeft;
  }

  const handleSubscribe = (e) => {
    e.preventDefault();
    alert("Thank you for subscribing!");
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-yellow-700 to-yellow-900 flex flex-col items-center justify-center text-white p-4">
      <div className="text-center">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">Launching Soon</h1>
        <p className="text-lg md:text-xl mb-6">
          We're working hard to bring you something amazing. Stay tuned!
        </p>
      </div>

      {/* Countdown Timer */}
      {timeLeft ? (
        <div className="flex space-x-4 text-center mb-8">
          {Object.entries(timeLeft).map(([unit, value]) => (
            <div key={unit} className="bg-white bg-opacity-10 p-4 rounded-lg">
              <p className="text-3xl font-bold">{value}</p>
              <p className="text-sm uppercase">{unit}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-2xl font-semibold mb-8">We're Live!</p>
      )}

      {/* Subscribe Form */}
      {/* <form onSubmit={handleSubscribe} className="w-full max-w-md">
        <div className="flex items-center bg-white bg-opacity-10 rounded-lg p-2">
          <input
            type="email"
            placeholder="Enter your email"
            required
            className="flex-grow bg-transparent border-none outline-none text-white placeholder-gray-300 px-4 py-2"
          />
          <button
            type="submit"
            className="bg-yellow-600 hover:bg-yellow-700 text-white px-4 py-2 rounded-lg font-semibold"
          >
            Notify Me
          </button>
        </div>
      </form> */}

      {/* Footer */}
      <div className="mt-12 text-sm text-gray-300">
        <p>© 2025 YourCompany. All rights reserved.</p>
      </div>
    </div>
  );
};

export default LaunchSoonPage;
