import React from "react";
import { FaAward, FaCamera, FaUsers, FaCheckCircle } from "react-icons/fa";

const AboutSection = () => {
  return (
    <section className="wrapper !bg-[#ffffff]">
      <div className="container pt-14 xl:pt-[4.5rem] lg:pt-[4.5rem] md:pt-[4.5rem] pb-[4.5rem] xl:pb-24 lg:pb-24 md:pb-24">
        {/* Welcome Section */}
        <div className="flex flex-wrap mx-[-15px] md:mx-[-20px] lg:mx-[-20px] xl:mx-[-35px] mt-[-30px] items-center">
          <div className="md:w-8/12 lg:w-6/12 xl:w-6/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] max-w-full !mx-auto mt-[30px]">
            <div className="img-mask w-1/2 mx-auto">
              <img src="/assets/img/photos/about27.jpg" className="rounded-full  shadow-3xl border-2 border-yellow-600" alt="Photo Contest and Model Connect" />
            </div>
          </div>
          <div className="xl:w-6/12 lg:w-6/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] max-w-full mt-[30px]">
            <h2 className="xl:text-[1.7rem] text-[calc(1.295rem_+_0.54vw)] !leading-[1.25] font-semibold tracking-[normal] mb-5">
              Welcome to the Ultimate Photo Contest & Model Connect Portal
            </h2>
            <p className="!mb-6">
              Join a global community of aspiring and professional photographers to showcase your creative work, win exciting prizes, and connect with models for your next big project. Whether you are a photographer looking to capture stunning shots or a model seeking new opportunities, our platform connects talented individuals from around the world.
            </p>
            <div className="flex flex-wrap mx-[-15px] counter-wrapper mt-[-30px]">
              <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                <h3 className="counter xl:text-[2rem] text-[calc(1.325rem_+_0.9vw)] tracking-[normal] !leading-none font-semibold mb-2">50K+</h3>
                <p className="text-[0.8rem] font-medium !mb-0">Photographers Worldwide</p>
              </div>
              <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                <h3 className="counter xl:text-[2rem] text-[calc(1.325rem_+_0.9vw)] tracking-[normal] !leading-none font-semibold mb-2">10K+</h3>
                <p className="text-[0.8rem] font-medium !mb-0">Models Available</p>
              </div>
              <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 w-full flex-[0_0_auto] px-[15px] max-w-full mt-[30px]">
                <h3 className="counter xl:text-[2rem] text-[calc(1.325rem_+_0.9vw)] tracking-[normal] !leading-none font-semibold mb-2">100+</h3>
                <p className="text-[0.8rem] font-medium !mb-0">Photo Contests Held</p>
              </div>
            </div>
          </div>
        </div>

        {/* Process and Why Choose Me Section */}
        <div className="flex flex-wrap md:px-10 mx-[-15px] md:mx-[-20px] lg:mx-[-20px] xl:mx-[-35px] mt-8">
          <div className="xl:w-4/12 lg:w-4/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] md:px-[20px] px-[15px] max-w-full mt-[30px]">
            <h3 className="!text-[1.15rem] !leading-[1.4] font-semibold">My Process</h3>
            <ul className="pl-0 list-none">
              <li className="flex items-center mb-4">
                <FaCheckCircle className="mr-3 text-yellow-600" />
                <p>Understanding your creative vision</p>
              </li>
              <li className="flex items-center mb-4">
                <FaCheckCircle className="mr-3 text-yellow-600" />
                <p>Planning and organizing shoots with models</p>
              </li>
              <li className="flex items-center mb-4">
                <FaCheckCircle className="mr-3 text-yellow-600" />
                <p>Capturing stunning moments and emotions</p>
              </li>
            </ul>
          </div>

          <div className="xl:w-4/12 lg:w-4/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] md:px-[20px] px-[15px] max-w-full mt-[30px]">
            <h3 className="!text-[1.15rem] !leading-[1.4] font-semibold">Why Choose Me?</h3>
            <ul className="pl-0 list-none">
              <li className="flex items-center mb-4">
                <FaCheckCircle className="mr-3 text-yellow-600" />
                <p>Experienced professional with 500+ weddings shot</p>
              </li>
              <li className="flex items-center mb-4">
                <FaCheckCircle className="mr-3 text-yellow-600" />
                <p>Passionate about capturing authentic moments</p>
              </li>
              <li className="flex items-center mb-4">
                <FaCheckCircle className="mr-3 text-yellow-600" />
                <p>Exclusive model and photographer connect</p>
              </li>
            </ul>
          </div>
        </div>

        {/* Additional Information Section */}
        <div className="flex flex-wrap md:px-10 mx-[-15px] md:mx-[-20px] lg:mx-[-20px] xl:mx-[-35px] mt-8">
          <div className="xl:w-4/12 lg:w-4/12 w-full  flex-[0_0_auto] xl:px-[35px] lg:px-[20px] md:px-[20px] px-[15px] max-w-full mt-[30px]">
            <h3 className="!text-[1.15rem] !leading-[1.4] font-semibold">Photo Submission Guidelines</h3>
            <p>Upload high-resolution JPEG or PNG files (up to 5MB each). Submit a maximum of three photos per contest.</p>
          </div>

          <div className="xl:w-4/12 lg:w-4/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] md:px-[20px] px-[15px] max-w-full mt-[30px]">
            <h3 className="!text-[1.15rem] !leading-[1.4] font-semibold">Voting & Judging</h3>
            <p>Public voting and expert panel judging ensure a fair evaluation. Limit one vote per person, per entry.</p>
          </div>

          <div className="xl:w-4/12 lg:w-4/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] md:px-[20px] px-[15px] max-w-full mt-[30px]">
            <h3 className="!text-[1.15rem] !leading-[1.4] font-semibold">Exciting Prizes</h3>
            <p>Win cash prizes, recognition, and portfolio development opportunities. Prizes distributed securely and promptly.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
