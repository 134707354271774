import React, { useState } from "react";

const Slider = () => {
  const slides = [
    {
      id: 1,
      image: "/slider2.jpg",
      thumbnail: "/assets/img/photos/bg29-th.jpg",
      title: "Join Our Global Photo Contest 2025",
      subtitle: "Submit Your Best Shots & Win Exciting Prizes",
      alt: "Photo Contest Banner - Showcase Your Photography Skills",
    },
    {
      id: 2,
      image: "/slider3.jpg",
      thumbnail: "/assets/img/photos/bg31-th.jpg",
      title: "Calling All Photographers & Creators",
      subtitle: "A Platform to Shine & Gain Recognition",
      alt: "Photography Contest for Professionals & Amateurs",
    },
    {
      id: 3,
      image: "/slider1.jpg",
      thumbnail: "/assets/img/photos/bg30-th.jpg",
      title: "Your Photos Deserve the Spotlight",
      subtitle: "Participate & Get Featured Worldwide",
      alt: "Photography Competition - Showcase Your Talent",
    },
    {
      id: 4,
      image: "/slider2.jpg",
      thumbnail: "/assets/img/photos/bg31-th.jpg",
      title: "Capture Moments, Tell Stories",
      subtitle: "Turn Your Passion into Opportunities",
      alt: "Photography Challenge for Creatives",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <section className="relative bg-white opacity-100">
      {/* Main Slider */}
      <div className="relative z-10">
        <div className="swiper-container swiper-fullscreen nav-dark relative z-10">
          <div className="swiper-wrapper">
            {slides.map((slide, index) => (
              <div
                key={slide.id}
                className={`swiper-slide bg-[#21262c] opacity-100 bg-image !bg-[center_center] before:content-[''] before:block before:z-[1] before:w-full before:h-full before:left-0 before:top-0 before:bg-[rgba(30,34,40,.4)] ${
                  index === currentSlide ? "block" : "hidden"
                }`}
                style={{ backgroundImage: `url(${slide.image})` }}
              >
                {/* Overlay Content */}
                <div className="absolute inset-0 bg-[rgba(44,53,66,0.4)] flex items-center justify-center">
                  <div className="text-center text-white px-4">
                    <h1 className="text-xl uppercase text-white tracking-wider font-semibold mb-3">
                      {slide.title}
                    </h1>
                    <h2 className="text-3xl text-white font-semibold mb-6">
                      {slide.subtitle}
                    </h2>

                    {/* Buttons */}
                    <div className="flex justify-center gap-4">
                      <a
                        href="/register"
                        className="bg-yellow-600 text-gray-200 hover:text-white py-2 px-6 rounded-lg hover:bg-yellow-800 hover:scale-105 transform transition-all duration-300 ease-in-out focus:outline-none"
                        aria-label="Submit your entry to the photo contest"
                      >
                        Submit Your Entry
                      </a>

                      <a
                        href="/about-photo-contest"
                        className="bg-transparent border-2 border-white text-white py-2 px-6 rounded-lg hover:bg-white hover:text-gray-800 hover:scale-105 transform transition-all duration-300 ease-in-out focus:outline-none"
                        aria-label="Learn more about the photo contest"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Navigation Buttons */}
        <button
          onClick={goToPrevSlide}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full hover:bg-gray-700 z-10"
          aria-label="Previous slide"
        >
          ❮
        </button>
        <button
          onClick={goToNextSlide}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full hover:bg-gray-700 z-10"
          aria-label="Next slide"
        >
          ❯
        </button>
      </div>
    </section>
  );
};

export default Slider;
