import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

// Utility function to clear the tokens
const logout = () => {
  localStorage.removeItem('access_token'); // Remove the access token
  localStorage.removeItem('refresh_token'); // Remove the refresh token
};

const LogoutPage = () => {
  useEffect(() => {
    logout(); // Clear tokens when the component is mounted
  }, []);

  // Redirect the user to the homepage or login page after logging out
  return <Navigate to="/" />;
};

export default LogoutPage;
