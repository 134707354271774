import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import httpCommon from '../services/httpCommon';
import { FaPencilAlt, FaVoteYea, FaUpload, FaAward } from 'react-icons/fa';
import Header from '../components/Header';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false); // Track terms acceptance
  const navigate = useNavigate();

  const handleLogin = async () => {
    

    setIsLoading(true);
    try {
      const response = await httpCommon.post('/api/login/', {
        username,
        password,
      });
      setMessage('OTP sent to your email.');
      setIsLoading(false);

      // Navigate to the OTP verification page with the OTP ID
      navigate(`/login-verify-otp/${response.data.otp_id}`);
    } catch (error) {
      setMessage('Login failed. Please check your credentials.');
      setIsLoading(false);
    }
  };

  return (
    <>
   <section className='mb-4'>
   <Header />
   </section>
    <div className="flex mt-20 m-auto flex-col lg:flex-row items-center justify-center min-h-screen bg-gray-50 p-4 px-4">
      {/* Login Form */}
      <div className="w-full lg:w-1/2 bg-white p-6 lg:p-12 rounded-lg shadow-lg mb-8 lg:mb-0">
        <h2 className="text-2xl font-semibold text-center text-yellow-800 mb-6">Login</h2>
        <div className="mb-4">
          <input
            type="email"
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-600"
            placeholder="Email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-600"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="terms"
            className="mr-2 bg-blue-400"
            checked={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
          />
          <label htmlFor="terms" className="text-gray-700">
            I accept the <a href="/terms-and-conditions" className="text-yellow-600 underline">terms and conditions</a>
          </label>
        </div>
        {message && <p className="text-center text-red-500 mb-4">{message}</p>}
        <button
          onClick={handleLogin}
          className={`w-full p-3 bg-yellow-600 text-white rounded-md ${
            isLoading? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isLoading }
        >
          {isLoading ? 'Loading...' : 'Login'}
        </button>
        <p className="mt-4 text-center text-gray-700">
          Not registered yet?{' '}
          <a href="/register" className="text-yellow-600 underline">
            Create an account
          </a>
        </p>
      </div>

      {/* Guidelines Section */}
      <div className="w-full lg:w-1/2 px-4">
        <div className="space-y-6">
          <div className="single-activities hover:bg-yellow-100 hover:text-black transition-all rounded-lg p-4 shadow-lg">
            <div className="media flex items-center space-x-4">
              <div className="icon bg-yellow-700 p-4 rounded-full text-white">
                <FaPencilAlt />
              </div>
              <div className="media-body">
                <h4 className="text-lg font-semibold">Eligibility Rules</h4>
                <p className="text-sm text-gray-700">
                  Open to aspiring and professional photographers worldwide. Age restrictions and regional eligibility may apply.
                </p>
              </div>
            </div>
          </div>
          <div className="single-activities hover:bg-yellow-100 hover:text-black transition-all rounded-lg p-4 shadow-lg">
            <div className="media flex items-center space-x-4">
              <div className="icon bg-yellow-700 p-4 rounded-full text-white">
                <FaUpload />
              </div>
              <div className="media-body">
                <h4 className="text-lg font-semibold">Photo Submission Guidelines</h4>
                <p className="text-sm text-gray-700">
                  Upload high-resolution JPEG or PNG files (up to 5MB each). Submit a maximum of three photos per contest.
                </p>
              </div>
            </div>
          </div>
          <div className="single-activities hover:bg-yellow-100 hover:text-black transition-all rounded-lg p-4 shadow-lg">
            <div className="media flex items-center space-x-4">
              <div className="icon bg-yellow-700 p-4 rounded-full text-white">
                <FaVoteYea />
              </div>
              <div className="media-body">
                <h4 className="text-lg font-semibold">Voting & Judging</h4>
                <p className="text-sm text-gray-700">
                  Public voting and expert panel judging ensure a fair evaluation. Limit one vote per person, per entry.
                </p>
              </div>
            </div>
          </div>
          <div className="single-activities hover:bg-yellow-100 hover:text-black transition-all rounded-lg p-4 shadow-lg">
            <div className="media flex items-center space-x-4">
              <div className="icon bg-yellow-700 p-4 rounded-full text-white">
                <FaAward />
              </div>
              <div className="media-body">
                <h4 className="text-lg font-semibold">Exciting Prizes</h4>
                <p className="text-sm text-gray-700">
                  Win cash prizes, recognition, and portfolio development opportunities. Prizes distributed securely and promptly.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-8">
          <a href='/register'>
          <button className="bg-yellow-600 text-white py-2 px-6 rounded-full shadow-lg hover:bg-yellow-700 transition duration-300">
            Join the Contest
          </button>
          </a>
        </div>
      </div>
    </div>
    
    </>
  );
};

export default LoginPage;
