import React, { useState, useEffect } from "react";

// Assuming you have galleryData.json in the public folder or importing it

const Portfolio = () => {
  const galleryItems = [
    {
      "id": 1,
      "title": "Wedding",
      "image": "assets/img/photos/fs1.jpg",
      "link": "#",
      "caption": "View Gallery"
    },
    {
      "id": 2,
      "title": "Couples",
      "image": "assets/img/photos/fs2.jpg",
      "link": "#",
      "caption": "View Gallery"
    },
    {
      "id": 3,
      "title": "Engagement",
      "image": "assets/img/photos/fs3.jpg",
      "link": "#",
      "caption": "View Gallery"
    }
  ];

  

  return (
    <section className="wrapper !bg-[#ffffff]">
      <div className="container py-[4.5rem] xl:!py-24 lg:!py-24 md:!py-24">
        <div className="flex flex-wrap mx-[-15px]">
          <div className="lg:w-full xl:w-10/12 xxl:w-8/12 flex-[0_0_auto] px-[15px] max-w-full !mx-auto !text-center">
            <i className="icn-flower text-[#7cb798] xl:!text-[1.5rem] text-[calc(1.275rem_+_0.3vw)] opacity-25 before:content-['\e907']"></i>
            <h2 className="xl:text-[1.7rem] text-[calc(1.295rem_+_0.54vw)] !leading-[1.25] font-semibold tracking-[normal] !text-center !mt-2 mb-10">
              I absolutely love shooting weddings and couples because there are so many feelings to capture.
            </h2>
          </div>
        </div>

        <div className="flex flex-wrap mx-[-15px] grid-view md:mx-[-20px] lg:mx-[-20px] xl:mx-[-25px] mt-[-40px] xl:mt-0 lg:mt-0 !text-center">
          {galleryItems.map((item) => (
            <div key={item.id} className="sm:w-8/12 md:w-6/12 lg:w-4/12 xl:w-4/12 w-full flex-[0_0_auto] xl:px-[25px] lg:px-[20px] md:px-[20px] px-[15px] max-w-full !mx-auto md:mt-[40px] sm:mt-[40px] xsm:mt-[40px]">
              <div className="card !shadow-[0_0.25rem_1.75rem_rgba(30,34,40,0.07)]">
                <figure className="card-img-top overlay overlay-1 group">
                  <a className="relative block z-[3] cursor-pointer inset-0" href={item.link}>
                    <img className="max-w-full h-auto" src={item.image} alt={item.title} />
                  </a>
                  <figcaption className="group-hover:opacity-100 absolute w-full h-full opacity-0 text-center px-4 py-3 inset-0 z-[5] pointer-events-none p-2">
                    <h5 className="from-top !mb-0 !absolute w-full translate-y-[-80%] px-4 py-3 left-0 top-2/4 !text-white">{item.caption}</h5>
                  </figcaption>
                </figure>
                <div className="card-body p-6">
                  <h3 className="text-[1.05rem] !mb-0">{item.title}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
