import React, { useState } from "react";

const blogPosts = [
  {
    image: "/assets/img/photos/b12.jpg",
    title: "Photography Tips for Winning Contests",
    category: "Photography",
    date: "14 Apr 2022",
    comments: 4,
    link: "/blog-post-1",
  },
  {
    image: "/assets/img/photos/b13.jpg",
    title: "Meet the Winner: John Doe's Winning Shot",
    category: "Contest Winner",
    date: "29 Mar 2022",
    comments: 3,
    link: "/blog-post-2",
  },
  {
    image: "/assets/img/photos/b14.jpg",
    title: "Best Lenses for Portrait Photography",
    category: "Lenses",
    date: "26 Feb 2022",
    comments: 6,
    link: "/blog-post-3",
  },
  {
    image: "/assets/img/photos/b15.jpg",
    title: "Top 5 Photography Models to Watch in 2022",
    category: "Models",
    date: "17 Jan 2022",
    comments: 3,
    link: "/blog-post-4",
  },
  {
    image: "/assets/img/photos/b16.jpg",
    title: "Engagement Photography: Tips and Tricks",
    category: "Photography",
    date: "7 Jan 2022",
    comments: 1,
    link: "/blog-post-5",
  },
  {
    image: "/assets/img/photos/b17.jpg",
    title: "How to Choose the Right Lens for Your Camera",
    category: "Lenses",
    date: "2 Jan 2022",
    comments: 2,
    link: "/blog-post-6",
  },
];

const BlogSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const postsPerSlide = 4;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % (blogPosts.length - postsPerSlide + 1));
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + (blogPosts.length - postsPerSlide + 1)) % (blogPosts.length - postsPerSlide + 1)
    );
  };

  const getCurrentPosts = () => {
    return blogPosts.slice(currentIndex, currentIndex + postsPerSlide);
  };
  

  return (
    <section className="wrapper bg-[#f3f8f5] py-12 md:py-24">
      <div className="container overflow-hidden">
        <div className="text-center mb-10">
          <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold tracking-tight">
            Here are the latest posts from my blog that grabbed the most attention.
          </h2>
        </div>

        {/* Custom Slider */}
        <div className="swiper-container nav-bottom nav-color mb-12 relative z-10">
          <div className="swiper-wrapper flex">
            {getCurrentPosts().map((post, index) => (
              <div
                className="swiper-slide flex-shrink-0 w-full sm:w-1/2 lg:w-1/4 px-2 mb-6"
                key={index}
              >
                <article>
                  <div className="card shadow-lg">
                    <figure className="card-img-top overlay overlay-1 group">
                      <a href={post.link}>
                        <img
                          className="max-w-full h-auto"
                          src={post.image}
                          srcSet={`${post.image} 2x`}
                          alt={post.title}
                        />
                        <span className="bg"></span>
                      </a>
                      <figcaption className="group-hover:opacity-100 absolute w-full h-full opacity-0 text-center px-4 py-3 inset-0 z-[5] pointer-events-none p-2">
                        <h5 className="from-top !mb-0 absolute w-full translate-y-[-80%] px-4 py-3 left-0 top-2/4 group-hover:-translate-y-2/4">
                          Read More
                        </h5>
                      </figcaption>
                    </figure>
                    <div className="card-body p-6">
                      <div className="post-header">
                        <div className="post-category mb-[.4rem] uppercase tracking-[0.02rem] text-[#aab0bc]">
                          <a href="#" className="hover:text-[#7cb798]">
                            {post.category}
                          </a>
                        </div>
                        <h2 className="post-title h3 !mt-1 !mb-3 !text-[1.15rem] !font-semibold !tracking-[normal]">
                          <a
                            className="text-[#343f52] hover:text-[#7cb798]"
                            href={post.link}
                          >
                            {post.title}
                          </a>
                        </h2>
                      </div>
                      <div className="post-footer">
                        <ul className="text-[0.75rem] text-[#aab0bc] m-0 p-0 list-none flex !mb-0">
                          <li className="post-date inline-block">
                            <i className="uil uil-calendar-alt pr-[0.2rem] align-[-.05rem] before:content-['\e9ba']"></i>
                            <span>{post.date}</span>
                          </li>
                          <li className="post-comments inline-block before:content-[''] before:inline-block before:w-[0.2rem] before:h-[0.2rem] before:opacity-50 before:m-[0_.6rem_0] before:rounded-[100%] before:align-[.15rem] before:bg-[#aab0bc]">
                            <a
                              className="text-[#aab0bc] hover:text-[#7cb798] hover:border-[#7cb798]"
                              href="#"
                            >
                              <i className="uil uil-comment pr-[0.2rem] align-[-.05rem] before:content-['\ea54']"></i>
                              {post.comments}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            ))}
          </div>

          {/* Controls */}
          <div className="swiper-controls ">
            <div className="swiper-navigation py-4">
              <div
                className="swiper-button swiper-button-prev"
                onClick={prevSlide}
              >
                
              </div>
              <div
                className="swiper-button swiper-button-next"
                onClick={nextSlide}
              >
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
