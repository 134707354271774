import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaFacebook, FaTelegram, FaWhatsapp } from "react-icons/fa"; // Importing social media icons
import { fetchPhotoListRequest, likePhotoRequest } from "../redux/actions/photoActions";

const PhotoListPage = ({ competitionCode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { photosData = [], loading, error } = useSelector((state) => state.photos);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // State to store the search query

  // Filter the photos based on the search query, if photosData is available
  const filteredPhotos = photosData.filter((photo) =>
    photo.code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (competitionCode) {
      dispatch(fetchPhotoListRequest(competitionCode)); // Fetch photo list initially
    }
  }, [dispatch, competitionCode]);

  const handleImageClick = (photo) => {
    setSelectedPhoto(photo);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPhoto(null);
  };

  const toggleLike = (photoId, userVoted) => {
    const accessToken = localStorage.getItem("access_token");

    if (!accessToken) {
      navigate("/login");
    } else {
      const likeValue = userVoted ? 0 : 1; // Toggle between like (1) and unlike (0)

      // Dispatch like/unlike action
      dispatch(likePhotoRequest(photoId, likeValue));

      window.location.reload(); // Refresh page after action
    }
  };

  const sharePhoto = (photoUrl) => {
    const encodedUrl = encodeURIComponent(photoUrl);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`, "_blank");
    window.open(`https://t.me/share/url?url=${encodedUrl}`, "_blank");
    window.open(`https://wa.me/?text=${encodedUrl}`, "_blank");
  };

  if (loading) return <div className="text-center text-yellow-700">Loading photos...</div>;
  if (error) return <div className="text-center text-red-500">Error: {error}</div>;
  if (!photosData.length) return <div className="text-center text-gray-500">No photos available</div>;

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      {/* Search Bar */}
      <div className="flex justify-center mb-6">
        <input
          type="text"
          className="p-3 w-1/2 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-600"
          placeholder="Search by photo code..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} // Update search query on input change
        />
      </div>

      <h1 className="text-3xl font-bold text-center text-yellow-700 mb-6">Photo List</h1>

      {/* Photo Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {filteredPhotos.map((photo) => (
          <div key={photo.id} className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col justify-between">
            <img
              src={photo.image}
              alt={photo.title}
              className="w-full h-64 object-cover cursor-pointer"
              onClick={() => navigate(`/photo/${photo.code}`)} // Navigate to the photo detail page
            />
            <div className="p-6 flex-grow">
              <h2 className="text-xl font-semibold text-yellow-800">{photo.title}</h2>
              <div className="mt-2 mb-4">
                {/* Photo Code with Green Background */}
                <span className="px-3 py-1 text-sm rounded-full bg-green-500 text-white shadow-md">
                  {photo.code}
                </span>
              </div>
              <p className="text-gray-600">{photo.description}</p>
              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={() => toggleLike(photo.id, photo.user_voted)}
                  className={`text-2xl ${photo.user_voted ? "text-red-500" : "text-gray-400"} focus:outline-none`}
                >
                  ♥
                </button>
                <span className="text-sm text-gray-500">
                  Total Votes: {photo.total_votes || 0}
                </span>
                <span
                  className={`px-3 py-1 text-sm rounded-full ${photo.is_approved ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600"}`}
                >
                  {photo.is_approved ? "Approved" : "Not Approved"}
                </span>
              </div>
              {/* Social Media Share Buttons */}
              
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {isModalOpen && selectedPhoto && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div
            className="bg-white p-8 rounded-lg max-w-lg w-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-2 right-2 bg-gray-100 text-gray-600 rounded-full w-8 h-8 flex items-center justify-center focus:outline-none hover:bg-gray-200"
              onClick={closeModal}
            >
              ✕
            </button>
            <img
              src={selectedPhoto.image}
              alt={selectedPhoto.title}
              className="w-full h-auto mb-4 rounded-lg"
            />
            <div className="text-center">
              <h2 className="text-xl font-semibold text-yellow-800">{selectedPhoto.title}</h2>
              <p className="text-gray-600">{selectedPhoto.description}</p>
              <button
                onClick={() => toggleLike(selectedPhoto.id, selectedPhoto.user_voted)}
                className={`mt-4 px-4 py-2 rounded-full text-white ${selectedPhoto.user_voted ? "bg-red-500" : "bg-gray-500"}`}
              >
                {selectedPhoto.user_voted ? "Liked" : "Like"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoListPage;
