import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCompetitionRequest, fetchCategoriesRequest } from "../redux/actions/categoryAction";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";

const CompetitionForm = () => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.competition);
  const { data: categories } = useSelector((state) => state.categories);
  const navigate = useNavigate();

  const [competitionData, setCompetitionData] = useState({
    name: "",
    description: "",
    start_date: "",
    end_date: "",
    image: null,
    category: "",
  });

  const [prizeTiers, setPrizeTiers] = useState([
    { position: 1, prize_name: "", prize_value: "", prize_description: "" },
  ]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    dispatch(fetchCategoriesRequest());
  }, [dispatch]);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setCompetitionData((prev) => ({ ...prev, image: file }));
    setImagePreview(URL.createObjectURL(file));
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: "image/*" });

  const handleCompetitionChange = (e) => {
    const { name, value } = e.target;
    setCompetitionData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePrizeChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTiers = [...prizeTiers];
    updatedTiers[index][name] = value;
    setPrizeTiers(updatedTiers);
  };

  const addPrizeTier = () => {
    setPrizeTiers([...prizeTiers, { position: prizeTiers.length + 1, prize_name: "", prize_value: "", prize_description: "" }]);
  };

  const removePrizeTier = (index) => {
    setPrizeTiers(prizeTiers.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(createCompetitionRequest({ competitionData, prizeTiers }));
      setShowSuccessPopup(true);
      setTimeout(() => {
        setShowSuccessPopup(false);
        navigate(`/contests`);
      }, 3000);
    } catch (error) {
      console.error("Error during competition creation:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto p-4 bg-white shadow-lg rounded-lg space-y-4">
      <h2 className="text-xl font-bold text-gray-800 text-center">Create Competition</h2>
      <select name="category" required onChange={handleCompetitionChange} className="w-full p-2 border rounded">
        <option value="">Select Category</option>
        {categories.map((category) => (
          <option key={category.id} value={category.id}>{category.name}</option>
        ))}
      </select>
      <input type="text" name="name" required placeholder="Competition Name" onChange={handleCompetitionChange} className="w-full p-2 border rounded" />
      <input type="datetime-local" name="start_date" required onChange={handleCompetitionChange} className="w-full p-2 border rounded" />
      <input type="datetime-local" name="end_date" required onChange={handleCompetitionChange} className="w-full p-2 border rounded" />
      <textarea name="description" placeholder="Description" onChange={handleCompetitionChange} className="w-full p-2 border rounded"></textarea>
      
      <div {...getRootProps()} className="border-2 border-dashed p-4 text-center cursor-pointer">
        <input {...getInputProps()} />
        {imagePreview ? <img src={imagePreview} alt="Preview" className="h-32 mx-auto" /> : "Drag & drop an image or click to select"}
      </div>
      
      <h3 className="text-lg font-semibold">Prize Tiers</h3>
      {prizeTiers.map((tier, index) => (
        <div key={index} className="flex flex-wrap gap-2 border p-2 rounded">
          <input type="text" name="prize_name" placeholder="Prize Name" value={tier.prize_name} onChange={(e) => handlePrizeChange(index, e)} className="flex-1 p-2 border rounded" />
          <input type="number" name="prize_value" placeholder="Prize Value" value={tier.prize_value} onChange={(e) => handlePrizeChange(index, e)} className="p-2 border rounded w-24" />
          <input type="textarea" name="prize_description" placeholder="Prize Description" value={tier.prize_description} onChange={(e) => handlePrizeChange(index, e)} className="flex-1 p-2 border rounded" />
          {prizeTiers.length > 1 && <button type="button" className="text-red-500" onClick={() => removePrizeTier(index)}>Remove</button>}
        </div>
      ))}
      <button type="button" onClick={addPrizeTier} className="p-2 bg-blue-500 text-white rounded">Add Prize Tier</button>
      
      <button type="submit" className={`w-full p-2 text-white rounded ${loading ? "bg-gray-400" : "bg-green-500"}`} disabled={loading}>{loading ? "Submitting..." : "Create Competition"}</button>
      {error && <p className="text-red-500 text-center">{error}</p>}
      {showSuccessPopup && <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"><div className="bg-white p-6 rounded shadow-lg">Competition Created Successfully!</div></div>}
    </form>
  );
};

export default CompetitionForm;