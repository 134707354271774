// OTPVerification.js
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';  // Import useParams to retrieve OTP ID from the URL
import httpCommon from '../services/httpCommon';

const OTPVerification = () => {
  const { otp_id } = useParams();  // Get OTP ID from the URL
  const [otpCode, setOtpCode] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleVerifyOTP = async () => {
    setIsLoading(true);
    try {
      const response = await httpCommon.post('/api/login-verify-otp/', {
        otp_id: otp_id,
        otp_code: otpCode,
      });

      localStorage.setItem('access_token', response.data.access_token);
      localStorage.setItem('refresh_token', response.data.refresh_token);

      setMessage('OTP verified successfully!');
      setIsLoading(false);
      navigate(`/`);
      // Optionally, navigate to another page (e.g., dashboard) after success
    } catch (error) {
      setMessage('OTP verification failed. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-50 p-4">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-center text-yellow-800 mb-6">Verify OTP</h2>
        <div className="mb-6">
          <input
            type="text"
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-600"
            placeholder="Enter OTP"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        </div>
        {message && <p className="text-center text-red-500 mb-4">{message}</p>}
        <button
          onClick={handleVerifyOTP}
          className={`w-full p-3 bg-yellow-600 text-white rounded-md ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isLoading}
        >
          {isLoading ? 'Verifying OTP...' : 'Verify OTP'}
        </button>
      </div>
    </div>
  );
};

export default OTPVerification;
