import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/rootReducer';
import watchRegisterSaga from './sagas/registerSaga';
import { categoriesSaga } from './sagas/categorySaga';
import { competitionDetailSaga } from './sagas/competitionDetailSaga';
import { watchPhotoUpload } from './sagas/photoSaga';
import { photoListSaga } from './sagas/photoListSaga';
import likeSagas from './sagas/likeSaga';
import photoDetailSaga from './sagas/photoDetailSaga';


const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(watchRegisterSaga);
sagaMiddleware.run(categoriesSaga);
sagaMiddleware.run(competitionDetailSaga);
sagaMiddleware.run(watchPhotoUpload);
sagaMiddleware.run(photoListSaga);
sagaMiddleware.run(likeSagas);
sagaMiddleware.run(photoDetailSaga);

export default store;
