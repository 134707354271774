import React from 'react';

const WinnerPrizeCard = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-xl shadow-2xl hover:shadow-xl transition-shadow duration-300">
      <div className="text-center mb-8">
        <h2 className="text-xl font-semibold text-gray-800">Ewebhub Photo Contest 2025</h2>
        <p className="mt-2 text-md text-gray-600">Judge: Natasha Men</p>
        <p className="text-md text-gray-500">Messupy photographer</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Prizes Section */}
        <div className="flex flex-col justify-center items-center bg-gray-100 p-6 rounded-xl shadow-lg">
          <h3 className="text-md font-bold text-gray-800">Prizes</h3>
          <ul className="mt-4 space-y-4 text-lg text-gray-700">
            <li>
              <div className="flex text-sm items-center">
                <span className="text-yellow-500">🥇</span>
                <span className="ml-2 font-semibold">1st Prize:</span> ₹15,00
              </div>
            </li>
            <li>
              <div className="flex  text-sm items-center">
                <span className="text-gray-400">🥈</span>
                <span className="ml-2 font-semibold">2nd Prize:</span> ₹1,000
              </div>
            </li>
            <li>
              <div className="flex  text-sm items-center">
                <span className="text-brown-500">🥉</span>
                <span className="ml-2 font-semibold">3rd Prize:</span> ₹500
              </div>
            </li>
            <li>
              <div className="flex  text-sm items-center">
                <span className="text-gray-500">🏅</span>
                <span className="ml-2 font-semibold">All 7 Finalists:</span> ₹150
              </div>
            </li>
          </ul>
        </div>

        {/* Exhibition & Exposure Section */}
        <div className="flex flex-col justify-center items-center bg-gray-100 p-6 rounded-xl shadow-lg">
          <h3 className="text-md font-bold text-gray-800">Exhibition & Exposure</h3>
          <ul className="mt-4 space-y-4 text-lg text-gray-700">
            <li>
              <div className="flex  text-sm items-center">
                <span className="text-blue-500">📍</span>
                <span className="ml-2 font-semibold text-sm">Exhibition Location:</span> London, UK
              </div>
            </li>
            <li>
              <div className="flex  text-sm items-center">
                <span className="text-indigo-500">📸</span>
                <span className="ml-2 font-semibold">Instagram Exposure:</span> +1K followers
              </div>
            </li>
            <li>
              <div className="flex  text-sm items-center">
                <span className="text-blue-700">📱</span>
                <span className="ml-2 font-semibold">Facebook Exposure:</span> +3K followers
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* Call to Action */}
    
    </div>
  );
};

export default WinnerPrizeCard;
