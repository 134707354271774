import React, { useState } from 'react';

const BottomSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const items = [
    {
      icon: "fa-camera-retro",
      title: "Photo Branding",
      description: "Showcase your unique style and stand out with custom photo branding services.",
      linkText: "Read More",
      linkHref: "#"
    },
    {
      icon: "fa-file-image-o",
      title: "Photo Editing",
      description: "Transform your photos into stunning masterpieces with our professional editing.",
      linkText: "Read More",
      linkHref: "#"
    },
    {
      icon: "fa-camera",
      title: "Photoshoot",
      description: "Capture your special moments with our tailored photoshoot sessions.",
      linkText: "Read More",
      linkHref: "#"
    },
    {
      icon: "fa-camera-retro",
      title: "Photo Collection",
      description: "Explore our curated collections of stunning photographs from top photographers.",
      linkText: "Read More",
      linkHref: "#"
    },
    {
      icon: "fa-camera-retro",
      title: "Photo Contests",
      description: "Participate in exciting photo contests and win amazing prizes.",
      linkText: "Read More",
      linkHref: "#"
    },
  ];

  const visibleItems = 3;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 >= items.length - visibleItems + 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - visibleItems : prevIndex - 1
    );
  };

  return (
    <div className="slider-bottom-area pt-16 pb-14 bg-gray-100">
      <div className="container mx-auto relative">
        <div className="slider-bottom">
          <div className="flex overflow-hidden">
            <div
              className="flex transition-transform duration-500"
              style={{ transform: `translateX(-${currentIndex * 100 / visibleItems}%)` }}
            >
              {items.map((item, index) => (
                <div key={index} className="min-w-[33.333%] p-4">
                  <div className="bg-white shadow-md rounded-lg p-6 text-center">
                    <i className={`fa ${item.icon} text-4xl text-yellow-600 mb-4`}></i>
                    <h3 className="text-xl font-semibold mb-2">
                      <a href={item.linkHref} className="text-gray-800 hover:text-yellow-600">
                        {item.title}
                      </a>
                    </h3>
                    <p className="text-gray-600 mb-4">{item.description}</p>
                    <div className="read-more">
                      <a href={item.linkHref} className="text-yellow-600 hover:underline">{item.linkText}</a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button onClick={prevSlide} className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-yellow-600 text-white p-2 rounded-full">
            <i className="fa fa-angle-left"></i>
          </button>
          <button onClick={nextSlide} className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-yellow-600 text-white p-2 rounded-full">
            <i className="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BottomSlider;
