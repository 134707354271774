import { combineReducers } from 'redux';
import registerReducer from './registerReducer';
import { competitionsReducer } from './competitionsReducer';
import { categoriesReducer } from './categoriesReducer';
import competitionDetailReducer from './competitionDetailReducer';
import photoReducer from './photoReducer';
import photoListReducer from './photoListReducer';
import { likeReducer } from './likeReducer';
import { photoDetailReducer } from './photoDetailReducer';


const rootReducer = combineReducers({
  register: registerReducer,
  categories: categoriesReducer,
  competitions: competitionsReducer,
  competition: competitionDetailReducer,
  photoUpload:photoReducer,
  photos:photoListReducer,
  votes:likeReducer,
  photoDetail:photoDetailReducer
});

export default rootReducer;
