// reducers/competitionDetailReducer.js
import {
    FETCH_COMPETITION_DETAIL_SUCCESS,
    FETCH_COMPETITION_DETAIL_FAILURE,
    CREATE_COMPETITION_REQUEST,
    CREATE_COMPETITION_SUCCESS,
    CREATE_COMPETITION_FAILURE
  } from "../actions/categoryAction";
  
  const initialState = {
    competitionDetail: null,
    loading: false,
    error: null,
    successMsg:null
  };
  
  const competitionDetailReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COMPETITION_DETAIL_SUCCESS:
        return {
          ...state,
          competitionDetail: action.payload,
          loading: false,
          error: null,
        };
      case FETCH_COMPETITION_DETAIL_FAILURE:
        return {
          ...state,
          competitionDetail: null,
          loading: false,
          error: action.payload,
        };
        case CREATE_COMPETITION_REQUEST:
          return { ...state, loading: true, error: null };
        case CREATE_COMPETITION_SUCCESS:
          return { 
            ...state, 
            loading: false, 
            successMsg: "Competition created successfully", // Added success message for creation
            competitionDetail: action.payload // Assuming you want to add the new competition to the existing list
          };
        case CREATE_COMPETITION_FAILURE:
          return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default competitionDetailReducer;
  