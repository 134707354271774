import React from "react";

const Skills = () => {
  const skills = [
    { name: "Python", level: "Intermediate" },
    { name: "Django", level: "Advanced" },
    { name: "FastAPI", level: "Intermediate" },
    { name: "React.js", level: "Intermediate" },
  ];

  return (
    <section id="skills" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Skills</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {skills.map((skill, index) => (
            <div
              key={index}
              className="bg-gray-100 p-4 rounded-lg shadow hover:shadow-lg transition"
            >
              <h3 className="text-lg font-bold">{skill.name}</h3>
              <p className="text-gray-600">{skill.level}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
