import React from "react";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Slider from "../components/Slider";
import BottomSlider from "../components/BottomSlider";
import About from "../components/About";
import Portfolio from "../components/Portfolio";
import ContestFeature from "../components/ContestFeature";
import RunningContest from "../components/RunningContest";
import ProjectActivation from "../components/ProjectActivation";
import Skills from "../components/Skills";
import Projects from "../components/Projects";
import Experience from "../components/Experience";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import CategoryCompetitionSection from "../components/CategoryCompetitionSection";

const ContestList = () => {
  return (
    <div className="bg-gray-100 text-gray-900 ">
      {/* Navbar */}

      {/* Header Section */}
      <Header />

      <main>
        {/* Slider Section */}
        <section className="mt-20">
          <CategoryCompetitionSection />
        </section>

        

       
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ContestList;
