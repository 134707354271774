import React from 'react';

const CancellationsAndRefunds = () => {
  return (
    <section id="cancellation-policy" className="wrapper p-24">
      <div className="container pt-4 pb-4 !text-center">
        <div className="flex flex-wrap">
          <div className="md:w-10/12 lg:w-8/12 xl:w-7/12 xxl:w-6/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto">
            <h1 className="text-[calc(1.365rem_+_1.38vw)] font-bold leading-[1.2] xl:text-[2.4rem] !mb-3">
              Cancellation Policy
            </h1>
            <nav className="inline-block" aria-label="breadcrumb">
              <ol className="breadcrumb flex flex-wrap bg-[none] mb-4 p-0 !rounded-none list-none">
                <li className="breadcrumb-item flex text-[#60697b]">
                  <a className="text-inherit text-[#60697b]" href="#">
                    Home
                  </a>
                </li>
                <li
                  className="breadcrumb-item active flex text-[#60697b] pl-2 before:font-normal before:flex before:items-center before:text-[rgba(96,105,123,.35)] before:content-['\e931'] before:text-[0.9rem] before:-mt-px before:pr-2 before:font-Unicons"
                  aria-current="page"
                >
                  Cancellation Policy
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body !p-10">
          <h2 className="!mb-3 !leading-[1.35]">1. Introduction</h2>
          <p>
            This Cancellation Policy outlines the terms and conditions for cancelling your participation in our photo contest. By participating in the contest, you agree to abide by the cancellation policies described here.
          </p>

          <h3 className="text-xl font-semibold mt-8">2. How to Cancel Your Participation</h3>
          <p>To cancel your participation in the photo contest, please follow the steps below:</p>
          <ul className="pl-0 list-none bullet-bg bullet-soft-primary !mb-0">
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Log in to your account and go to the contest dashboard.</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Click on the “Cancel Participation” button located on the contest page.</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Confirm the cancellation by clicking on the confirmation link sent to your registered email address.</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Your participation will be cancelled and you will no longer be eligible for voting or prizes.</span>
            </li>
          </ul>

          <h3 className="text-xl font-semibold mt-8">3. Cancellation Deadline</h3>
          <p>To ensure a fair contest, cancellations must be requested within the following time frame:</p>
          <ul className="pl-0 list-none bullet-bg bullet-soft-primary !mb-0">
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Cancellations must be completed within 24 hours of submitting your entry.</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>No cancellations will be allowed after the voting phase has started.</span>
            </li>
          </ul>

          <h3 className="text-xl font-semibold mt-8">4. Refunds</h3>
          <p>In the event of a cancellation, refunds will not be applicable, as participation fees are considered non-refundable once submitted.</p>

          <h3 className="text-xl font-semibold mt-8">5. Late Cancellations</h3>
          <p>If a cancellation request is made after the deadline or once the voting phase has started, the following actions may be taken:</p>
          <ul className="pl-0 list-none bullet-bg bullet-soft-primary !mb-0">
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Participants will be disqualified from receiving any prizes or awards.</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Late cancellations may affect eligibility for future contests.</span>
            </li>
          </ul>

          <h3 className="text-xl font-semibold mt-8">6. Contact Us</h3>
          <p>If you need further assistance with your cancellation, feel free to reach out to us:</p>
          <ul className="pl-0 list-none bullet-bg bullet-soft-primary !mb-0">
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-envelope w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>contact@ewebhub.com</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default CancellationsAndRefunds;
