// src/redux/reducers/competitionsReducer.js

import {
  FETCH_COMPETITIONS_REQUEST,
  FETCH_COMPETITIONS_SUCCESS,
  FETCH_COMPETITIONS_FAILURE,
  CREATE_COMPETITION_REQUEST,
  CREATE_COMPETITION_SUCCESS,
  CREATE_COMPETITION_FAILURE
} from "../actions/categoryAction";

const initialCompetitionsState = {
  data: [],
  loading: false,
  error: null,
  successMsg: null,
};

export const competitionsReducer = (state = initialCompetitionsState, action) => {
  switch (action.type) {
    case FETCH_COMPETITIONS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_COMPETITIONS_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        successMsg: "Competitions fetched successfully", 
        data: action.payload 
      };
    case FETCH_COMPETITIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case CREATE_COMPETITION_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_COMPETITION_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        successMsg: "Competition created successfully", // Added success message for creation
        data: [...state.data, action.payload] // Assuming you want to add the new competition to the existing list
      };
    case CREATE_COMPETITION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
