export const FETCH_PHOTO_DETAIL_REQUEST = 'FETCH_PHOTO_DETAIL_REQUEST';
export const FETCH_PHOTO_DETAIL_SUCCESS = 'FETCH_PHOTO_DETAIL_SUCCESS';
export const FETCH_PHOTO_DETAIL_FAILURE = 'FETCH_PHOTO_DETAIL_FAILURE';

export const fetchPhotoDetailRequest = (photoCode) => ({
  type: FETCH_PHOTO_DETAIL_REQUEST,
  payload: photoCode,
});

export const fetchPhotoDetailSuccess = (photo) => ({
  type: FETCH_PHOTO_DETAIL_SUCCESS,
  payload: photo,
});

export const fetchPhotoDetailFailure = (error) => ({
  type: FETCH_PHOTO_DETAIL_FAILURE,
  payload: error,
});
