import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoriesRequest, fetchCompetitionsRequest } from "../redux/actions/categoryAction";
import { Link } from "react-router-dom"; // Import Link for navigation
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FaFacebook, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import SEO from "./SEO";


const CategoryCompetitionSection = () => {
  const dispatch = useDispatch();
  const { data: categories, loading: categoriesLoading, error: categoriesError } = useSelector(
    (state) => state.categories
  );
  const { data: competitions, loading: competitionsLoading, error: competitionsError } = useSelector(
    (state) => state.competitions
  );

  useEffect(() => {
    dispatch(fetchCategoriesRequest());
    dispatch(fetchCompetitionsRequest());
  }, [dispatch]);

  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const filteredCompetitions = selectedCategory
    ? competitions.filter(
        (competition) => competition.category.id === selectedCategory.id
      )
    : competitions;

  // Function to truncate description to 30 characters but ensure the last word is not cut off
  const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) return description;

    // Split the description into words
    const words = description.split(" ");
    let truncated = "";

    // Keep adding words until the truncated description reaches the maxLength
    for (let word of words) {
      if ((truncated + word).length <= maxLength) {
        truncated += word + " ";
      } else {
        break;
      }
    }

    // Trim the extra space at the end and add ellipsis
    return truncated.trim() + "...";
  };

  return (
    <>
    <SEO
        title="Join the Photo Contest 2024 | EwebHub"
        description="Submit your best photography and win exciting prizes!"
        image="https://ewebhub.com/images/photo-contest-banner.JPG"
        url="https://ewebhub.com/"
      />
      <div className="container mx-auto p-4">
      {/* Categories as Tabs */}
      <div className="mb-8">
        <h4 className="text-2xl font-bold mb-4">Categories</h4>
        <div className="flex space-x-4 overflow-x-auto">
          {categoriesLoading ? (
            <div>Loading categories...</div>
          ) : categoriesError ? (
            <div>Error: {categoriesError}</div>
          ) : (
            categories.map((category) => (
              <div
                key={category.id}
                className={`px-4 py-2 cursor-pointer rounded-lg text-center font-medium ${
                  selectedCategory?.id === category.id
                    ? "bg-yellow-500 text-white"
                    : "bg-gray-200 hover:bg-gray-300"
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category.name}
              </div>
            ))
          )}
        </div>
      </div>

      {/* Competitions for the selected category */}
      <div>
        <h4 className="text-2xl font-bold mb-4">
          {selectedCategory
            ? `Competitions in ${selectedCategory.name}`
            : "All Competitions"}
        </h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-6">
          {competitionsLoading ? (
            <div>Loading competitions...</div>
          ) : competitionsError ? (
            <div>Error: {competitionsError}</div>
          ) : filteredCompetitions.length > 0 ? (
            filteredCompetitions.map((competition) => (
              <div
                key={competition.id}
                className="max-w-sm rounded overflow-hidden shadow-lg bg-white"
              >
                <div
                  className="h-48 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${competition.image})`,
                  }}
                ></div>
                <div className="p-4">
                  <h5 className="font-semibold text-lg">{competition.name}</h5>
                  <p className="text-sm text-gray-500 mt-2">
                    {competition.category.name}
                  </p>
                  <p className="text-sm mt-2">
                    {truncateDescription(competition.description, 100)}
                  </p>
                  <div className="mt-2 flex justify-between items-center text-sm">
                    <span>
                      {new Date(competition.start_date).toLocaleDateString()}
                    </span>
                    <Link
                      to={`/photo-contest-detail/${competition.code}`}
                      className="bg-yellow-500 text-white py-1 px-4 rounded hover:bg-yellow-600"
                    >
                      Register
                    </Link>
                  </div>
                </div>
                <div className="mt-6 flex justify-center space-x-4">
                  {/* Facebook Share */}
                  <FacebookShareButton
                  url={`https://ewebhub.com/contest-detail/${competition?.code}`} 
                  quote={encodeURIComponent(`🔥 Join the ${competition?.name} competition now! 🏆 Show your talent and participate today. Click the link below 👇`)}
  hashtag={`#eWebHubPhotoContest${competition?.code}`}
                >
                  <FaFacebook className="text-blue-600 text-3xl hover:opacity-75 cursor-pointer" />
                </FacebookShareButton>
                
                  {/* Twitter Share */}
                  <TwitterShareButton 
                    url={`https://ewebhub.com/contest-detail/${competition?.code}`} 
                    title={`🚀 Exciting contest alert! Participate in ewebhub🏅. Don't miss your chance! Click below: `}
                    hashtags={[`eWebHubPhotoContest${competition?.code}`]}
                  >
                    <FaTwitter className="text-blue-400 text-3xl hover:opacity-75 cursor-pointer" />
                  </TwitterShareButton>
                
                  {/* WhatsApp Share */}
                  <WhatsappShareButton 
                    url={`https://ewebhub.com/contest-detail/${competition?.code}`} 
                    title={`🎉 Join the ewebhub competition now! 🏆 Submit your best photos and win amazing prizes. Participate here: `}
                    separator=" "
                  >
                    <FaWhatsapp className="text-green-500 text-3xl hover:opacity-75 cursor-pointer" />
                  </WhatsappShareButton>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">No competitions found.</p>
          )}
        </div>
      </div>
    </div>
    </>
    
  );
};

export default CategoryCompetitionSection;
