// reducers/photoListReducer.js

import {
    FETCH_PHOTO_LIST_REQUEST,
    FETCH_PHOTO_LIST_SUCCESS,
    FETCH_PHOTO_LIST_FAILURE,
  } from '../actions/photoActions';
  
  const initialState = {
    photosData: [], // Default value as an empty array
    loading: false,
    error: null,
  };
  
  const photoListReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PHOTO_LIST_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_PHOTO_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          photosData: action.payload, // Store fetched photos
        };
      case FETCH_PHOTO_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload, // Store error message
        };
      default:
        return state;
    }
  };
  
  export default photoListReducer;
  