import React from "react";
import Header from "./Header";

const MyResume = () => {
  return (
    <div className="flex justify-center items-center p-6 bg-gray-50">
        <Header />
      <div className="max-w-4xl w-full bg-white shadow-xl rounded-lg p-8">
        <header className="text-center mb-8">
          <h1 className="text-3xl font-bold">Rishika Dutta</h1>
          <p className="text-sm text-gray-600">Chingrighata, Metropolitan Co-Operative Housing Society Limited, Tangra, Basundhara, Kolkata, West Bengal 700105</p>
          <p className="text-lg font-bold text-gray-600">
            Email: <a href="mailto:ewebhubofficial@gmail.com" className="text-blue-500 hover:underline">ewebhubofficial@gmail.com</a> | 
            Phone: <a href="tel:+919319965026" className="text-blue-500 hover:underline">(+919319965026)</a>
            </p>
        </header>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-700">Summary</h2>
          <p className="text-gray-600">
            I want to work in a challenging and stimulating environment, where I can learn new things and deliver my best for the dynamic growth of your company as well as my career.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-700">Education</h2>
          <p className="text-gray-600">
            <strong>National Institute of Technology, Rourkela</strong> <br />
            B.TECH in Computer Science and Engineering <br />
            Jamshedpur, Jharkhand, May 2015
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-700">Freelancing Projects</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-xl font-bold text-gray-700">Dynamic Chatbot Creation Platform</h3>
              <p className="text-gray-600">Project Title: Dynamic Chatbot Creation Platform using Langchain and Language Generation Techniques</p>
              <ul className="list-disc pl-5 text-gray-600">
                <li>Tools: Fast API, Langchain, NLP, Machine Learning, React JS, OpenCV</li>
                <li>Created a chatbot platform with OpenAI’s GPT-3 for custom chatbot design.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-700">Anonymous (Safe place for anonymous users)</h3>
              <p className="text-gray-600">Project Title: Anonymous (Safe place for anonymous users)</p>
              <ul className="list-disc pl-5 text-gray-600">
                <li>Tools: Django, Python, PostgreSQL, React JS, Bootstrap, XML, Numpy, Pandas</li>
                <li>Developed a platform for anonymous content sharing and discussions.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-700">Educational Bridge between Institutes, Alumni, and Students</h3>
              <p className="text-gray-600">Project Title: Three-way bridge between Educational Institutes, Alumni, and Students</p>
              <ul className="list-disc pl-5 text-gray-600">
                <li>Tools: Django, React JS, NumPy, Pandas, PostgreSQL</li>
                <li>Developed a platform for managing alumni networks and donations.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-700">AI Document Classification</h3>
              <p className="text-gray-600">Project Title: AI-based Document Classification System</p>
              <ul className="list-disc pl-5 text-gray-600">
                <li>Tools: Python, TensorFlow, OpenCV, NLP, Flask</li>
                <li>Developed an AI-based system for classifying documents and images based on content.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-700">Multivendor E-commerce Platform</h3>
              <p className="text-gray-600">Project Title: Multivendor E-commerce Site</p>
              <ul className="list-disc pl-5 text-gray-600">
                <li>Tools: React JS, Node.js, MongoDB, Express</li>
                <li>Built a multivendor e-commerce platform supporting multiple product listings and payment systems.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-700">Real-time Chat Application</h3>
              <p className="text-gray-600">Project Title: Real-time Chat Application</p>
              <ul className="list-disc pl-5 text-gray-600">
                <li>Tools: Node.js, Socket.io, React, Express, MongoDB</li>
                <li>Developed a real-time chat application with instant messaging and notifications.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-700">Portfolio Website</h3>
              <p className="text-gray-600">Project Title: Personal Portfolio Website</p>
              <ul className="list-disc pl-5 text-gray-600">
                <li>Tools: React JS, Tailwind CSS, Node.js</li>
                <li>Developed a modern portfolio website showcasing skills, experience, and projects.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-700">Task Management System</h3>
              <p className="text-gray-600">Project Title: Task Management System</p>
              <ul className="list-disc pl-5 text-gray-600">
                <li>Tools: React JS, Firebase, Node.js</li>
                <li>Developed a task management system with features like task creation, assignment, and tracking.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-700">AI Chatbot for Customer Service</h3>
              <p className="text-gray-600">Project Title: AI Chatbot for Customer Service</p>
              <ul className="list-disc pl-5 text-gray-600">
                <li>Tools: Python, TensorFlow, React JS</li>
                <li>Built an AI-powered chatbot to provide automated customer service on websites.</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-700">Skills</h2>
          <div className="grid grid-cols-2 gap-4">
            <ul className="list-disc pl-5 text-gray-600">
              <li>Python: Intermediate</li>
              <li>Django: Advanced</li>
              <li>FastAPI: Intermediate</li>
              <li>React JS: Intermediate</li>
            </ul>
            <ul className="list-disc pl-5 text-gray-600">
              <li>Machine Learning: OpenCV, Model Deployment, Generative AI</li>
              <li>Languages: Python, JavaScript</li>
              <li>Web Frameworks: Django Rest Framework, FastAPI</li>
              <li>Databases: PostgreSQL, MySQL</li>
            </ul>
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-700">Extra-Curricular Activities</h2>
          <ul className="list-disc pl-5 text-gray-600">
            <li>Active Member of SANKALP – A self-inspired group teaching underprivileged children.</li>
            <li>Member of CSI, NIT Jamshedpur.</li>
            <li>Participated in OJASS, technical fest of NIT Jamshedpur.</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-700">Languages</h2>
          <p className="text-gray-600">English: Proficient</p>
          <p className="text-gray-600">Hindi: Native</p>
        </section>

        <footer className="text-center text-gray-600 text-sm">
          <p>Declaration: I hereby declare that the aforementioned information is true and correct to the best of my knowledge.</p>
        </footer>
      </div>
    </div>
  );
};

export default MyResume;
