import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { uploadPhotoRequest } from '../redux/actions/photoActions';
import { fetchCompetitionDetailRequest } from '../redux/actions/categoryAction';
import Header from './Header';
import FAQComponent from './FAQComponent';
import WinnerPrizeCard from './WinnerPrizeCard';
import EnterCompetitionCard from './EnterCompetitionCard';

const PhotoUploadForm = () => {
  const dispatch = useDispatch();
  const { code: competitionId } = useParams();
  const navigate = useNavigate();

  const { competitionDetail } = useSelector((state) => state.competition);
  const { loading, error, photo } = useSelector((state) => state.photoUpload);

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    competition_code: competitionId,
    image: null,
  });
  const [imagePreview, setImagePreview] = useState(null); // State for image preview
  const [successMessage, setSuccessMessage] = useState(null); // State for success message

  // Fetch competition details when the component mounts
  useEffect(() => {
    dispatch(fetchCompetitionDetailRequest(competitionId));
  }, [competitionId, dispatch]);

  // Alert when photo upload is successful
  useEffect(() => {
    if (photo) {
      setSuccessMessage('Photo uploaded successfully!');
      setFormData({ title: '', description: '', image: null });
      setImagePreview(null); // Clear the image preview after upload
      setTimeout(() => navigate(`/photo-contest-detail/${competitionId}`), 2000); // Redirect after 2 seconds
    }
  }, [photo, competitionId, navigate]);

  // Handle input changes (text and file)
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === 'image' && files[0]) {
      const file = files[0];
      const fileSize = file.size / 1024 / 1024; // Convert size to MB
      const validFormats = ['image/jpeg', 'image/png'];

      // Check if the file is in the correct format
      if (!validFormats.includes(file.type)) {
        alert('Please upload an image in jpeg/jpg or png format.');
        return;
      }

      // Check if the file size is within the limit (20MB)
      if (fileSize > 20) {
        alert('File size should not exceed 20MB.');
        return;
      }

      // Set image preview
      setImagePreview(URL.createObjectURL(file));
    }

    setFormData((prev) => ({
      ...prev,
      [name]: name === 'image' ? files[0] : value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('title', formData.title);
    form.append('description', formData.description);
    form.append('competition_code', formData.competition_code);
    form.append('image', formData.image);

    dispatch(uploadPhotoRequest(form));
  };

  // Handle drag over event to allow dropping
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.add('drag-over'); // Optional, for styling when dragging over
  };

  // Handle drag leave event
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.remove('drag-over');
  };

  // Handle the drop event
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.classList.remove('drag-over');

    const file = e.dataTransfer.files[0]; // Get the dropped file

    if (file) {
      const fileSize = file.size / 1024 / 1024; // Convert size to MB
      const validFormats = ['image/jpeg', 'image/png'];

      // Check if the file is in the correct format
      if (!validFormats.includes(file.type)) {
        alert('Please upload an image in jpeg/jpg or png format.');
        return;
      }

      // Check if the file size is within the limit (20MB)
      if (fileSize > 20) {
        alert('File size should not exceed 20MB.');
        return;
      }

      setImagePreview(URL.createObjectURL(file)); // Set preview
      setFormData((prev) => ({
        ...prev,
        image: file,
      }));
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Header />

      <div className="flex flex-col lg:flex-row gap-6 mt-20">
        {/* Left Section: Upload Form */}
        <div className="flex-1 bg-white rounded-lg shadow-lg p-6 space-y-8">
        <EnterCompetitionCard end_date={competitionDetail?.end_date} /> 
          <h2 className="text-2xl font-semibold text-center mb-6">Upload Photo for Competition</h2>

          {loading && <p className="text-center text-blue-500">Uploading...</p>}
          {error && <p className="text-center text-red-500">{error}</p>}

          {/* Success message */}
          {successMessage && <p className="text-center text-green-500">{successMessage}</p>}

          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="mb-4">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                rows="4"
              ></textarea>
            </div>

            {/* Drag-and-Drop Upload Area */}
            <div
              className="mb-4 border-dashed border-2 border-gray-300 p-4 text-center"
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <p className="text-sm text-gray-500">Drag and drop your image here, or click to select</p>
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleChange}
                className="mt-2 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-indigo-100 file:text-indigo-700 hover:file:bg-indigo-200"
                required
              />
            </div>

            {/* Image Preview Section */}
            {imagePreview && (
              <div className="mb-4">
                <h3 className="text-sm font-medium text-gray-700">Image Preview</h3>
                <img src={imagePreview} alt="Preview" className="mt-2 w-full h-48 object-cover" />
              </div>
            )}

            <button
              type="submit"
              className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700"
              disabled={loading}
            >
              Upload Photo
            </button>
          </form>
        </div>

        {/* Right Section: Competition Details and FAQ */}
        <div className="w-full lg:w-1/3 flex flex-col gap-6">
          {/* Competition Details */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            {competitionDetail ? (
              <>
                <img
                  src={competitionDetail.image}
                  alt={competitionDetail.name || 'Contest Image'}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h1 className="text-xl font-semibold text-yellow-800">
                    {competitionDetail.name || 'Contest Name'}
                  </h1>
                  <p className="mt-4 text-gray-600">
                    {competitionDetail.description || 'No description available'}
                  </p>
                  <div className="mt-6 text-sm text-gray-500">
                    <p>
                      Category: <strong className='text-sm bg-green-600 px-4 text-white shadow-md rounded-md'>{competitionDetail.category?.name || 'Unknown'}</strong>
                    </p>
                    <p>
                      Start Date:{' '}
                      <strong>
                        {competitionDetail.start_date
                          ? new Date(competitionDetail.start_date).toLocaleDateString()
                          : 'N/A'}
                      </strong>
                    </p>
                    <p>
                      End Date:{' '}
                      <strong>
                        {competitionDetail.end_date
                          ? new Date(competitionDetail.end_date).toLocaleDateString()
                          : 'N/A'}
                      </strong>
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <p>Loading competition details...</p>
            )}
          </div>
          <WinnerPrizeCard />
          {/* FAQ Section */}
          <FAQComponent />
        </div>
      </div>
    </div>
  );
};

export default PhotoUploadForm;
