import React from "react";

const Navbar = () => {
  return (
    <header className="relative wrapper !bg-[#f3f8f5]">
      <nav className="navbar navbar-expand-lg center-nav transparent !absolute navbar-dark caret-none">
        <div className="container xl:flex-row lg:flex-row !flex-nowrap items-center">
          {/* Logo Section */}
          <div className="navbar-brand w-full">
            <a href="index.html">
              <img
                className="logo-dark"
                src="assets/img/logo-dark.png"
                srcSet="./assets/img/logo-dark@2x.png 2x"
                alt="Logo Dark"
              />
              <img
                className="logo-light"
                src="assets/img/logo-light.png"
                srcSet="./assets/img/logo-light@2x.png 2x"
                alt="Logo Light"
              />
            </a>
          </div>

          {/* Collapsible Menu */}
          <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
            {/* Offcanvas Header for Mobile */}
            <div className="offcanvas-header xl:hidden lg:hidden flex items-center justify-between flex-row p-6">
              <h3 className="text-white xl:text-[1.5rem] !text-[calc(1.275rem_+_0.3vw)] !mb-0">
                Sandbox
              </h3>
              <button
                type="button"
                className="btn-close btn-close-white mr-[-0.75rem] m-0 p-0 leading-none text-[#343f52] transition-all duration-[0.2s] ease-in-out border-0 motion-reduce:transition-none before:text-[1.05rem] before:content-['\ed3b'] before:w-[1.8rem] before:h-[1.8rem] before:leading-[1.8rem] before:shadow-none before:transition-[background] before:duration-[0.2s] before:ease-in-out before:flex before:justify-center before:items-center before:m-0 before:p-0 before:rounded-[100%] hover:no-underline bg-inherit before:bg-[rgba(255,255,255,.08)] before:font-Unicons hover:before:bg-[rgba(0,0,0,.11)] focus:outline-0"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            {/* Menu Items */}
            <div className="offcanvas-body xl:!ml-auto lg:!ml-auto flex flex-col !h-full">
              <ul className="navbar-nav">
                {/* Demos Dropdown */}
                <li className="nav-item dropdown dropdown-mega">
                  <a
                    className="nav-link dropdown-toggle !font-semibold !text-[.85rem] !tracking-[normal]"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Demos
                  </a>
                  <ul className="dropdown-menu mega-menu mega-menu-dark mega-menu-img">
                    <li className="mega-menu-content mega-menu-scroll">
                      <ul className="grid grid-cols-1 xl:grid-cols-6 lg:grid-cols-6 mx-0 xl:mx-[-10px] lg:mx-[-10px] xl:mt-[-10px] lg:mt-[-10px] !pl-0 list-none">
                        {/* Demo Item Example */}
                        <li className="xl:px-[10px] xl:mt-[10px] lg:px-[10px] lg:mt-[10px]">
                          <a className="dropdown-item" href="demo1.html">
                            <figure className="!rounded-[.4rem] lift hidden xl:block lg:block">
                              <img
                                className="!rounded-[.4rem]"
                                src="assets/img/demos/mi1.jpg"
                                srcSet="./assets/img/demos/mi1@2x.jpg 2x"
                                alt="Demo 1"
                              />
                            </figure>
                            <span className="xl:hidden lg:hidden">Demo 1</span>
                          </a>
                        </li>
                        {/* Add more demo items as needed */}
                      </ul>
                      <span className="hidden xl:flex lg:flex">
                        <i className="uil uil-direction before:content-['\ea93']"></i>
                        <strong>Scroll to view more</strong>
                      </span>
                    </li>
                  </ul>
                </li>

                {/* Other Dropdowns */}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle !font-semibold !text-[.85rem] !tracking-[normal]"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Pages
                  </a>
                  <ul className="dropdown-menu">
                    <li className="dropdown dropdown-submenu dropend">
                      <a
                        className="dropdown-item hover:!text-[#7cb798] dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        Services
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a
                            className="dropdown-item hover:!text-[#7cb798]"
                            href="services.html"
                          >
                            Services I
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="dropdown-item hover:!text-[#7cb798]"
                            href="services2.html"
                          >
                            Services II
                          </a>
                        </li>
                      </ul>
                    </li>
                    {/* Add other dropdown items as needed */}
                  </ul>
                </li>

                {/* Example Static Item */}
                <li className="nav-item">
                  <a
                    className="nav-link !font-semibold !text-[.85rem] !tracking-[normal]"
                    href="#"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
