import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_PHOTO_DETAIL_REQUEST } from '../actions/photoDetailActions';
import { fetchPhotoDetailSuccess, fetchPhotoDetailFailure } from '../actions/photoDetailActions';
import httpCommon from '../../services/httpCommon'; // Axios instance

function* fetchPhotoDetailSaga(action) {
    try {
      // Get access token from localStorage
      const accessToken = localStorage.getItem("access_token");
  
      // Set headers object
      let headers = {};
  
      // If token exists, add it to headers
      if (accessToken) {
        headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
  
      // Fetch the photo detail using the action payload (photo code)
      const response = yield call(
        httpCommon.get,
        `/api/photo-detail/${action.payload}/`,
        { headers: headers }
      );
  
      // Dispatch success action with the response data
      yield put(fetchPhotoDetailSuccess(response.data));
    } catch (error) {
      // Dispatch failure action with error message
      yield put(fetchPhotoDetailFailure(error.message));
    }
  }
  
  export default function* photoDetailSaga() {
    yield takeLatest(FETCH_PHOTO_DETAIL_REQUEST, fetchPhotoDetailSaga);
  }


