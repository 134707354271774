import React, { useEffect, useState } from 'react';

const EnterCompetitionCard = ({ end_date }) => {
  // State to manage login status
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check localStorage for login status when the component mounts
  useEffect(() => {
    const userToken = localStorage.getItem('access_token'); // Assuming you store token in localStorage
    if (userToken) {
      setIsAuthenticated(true); // If the token is found, the user is logged in
    } else {
      setIsAuthenticated(false); // If the token is not found, the user is not logged in
    }
  }, []);

  // Format the end_date
  const formattedDate = end_date
    ? new Date(end_date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      })
    : null;

  return (
    <div className="flex justify-center items-center">
      <div className="bg-gradient-to-r from-yellow-600 to-indigo-600 text-white rounded-lg p-8 shadow-2xl w-full max-w-md mx-auto">
        {/* Title and Action */}
        <div className="text-center">
          <h2 className="text-2xl md:text-4xl font-extrabold mb-6">Enter Competition</h2>

          {/* Deadline */}
          <div className="text-center mb-4">
            <span className="text-sm text-gray-200">Deadline: </span>
            <span className="text-md sm:text-lg text-yellow-400 font-semibold">{formattedDate}</span>
          </div>

          {/* Buttons, shown only if the user is not authenticated */}
          {!isAuthenticated && (
            <div className="mt-6 flex justify-center space-x-4">
              <a
                href="/register"
                className="bg-white text-yellow-600 hover:bg-yellow-100 font-bold py-2 px-6 rounded-full shadow-md transition duration-300"
              >
                Join Now
              </a>
              <a
                href="/launch-soon"
                className="bg-transparent border-2 border-white text-white hover:bg-white hover:text-yellow-600 font-bold py-2 px-6 rounded-full shadow-md transition duration-300"
              >
                Learn More
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnterCompetitionCard;
