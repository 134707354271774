import React, { useState } from 'react';

const TermsAndConditions = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [isOpen, setIsOpen] = useState({
    section1: false,
    section2: false,
    section3: false,
  });

  const toggleSection = (section) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleAccept = (e) => {
    setIsAccepted(e.target.checked);
  };

  return (
    <section id="terms-conditions" class="wrapper p-24">
      <div class="container pt-4 pb-4   !text-center">
        <div class="flex flex-wrap ">
          <div class="md:w-10/12 lg:w-8/12 xl:w-7/12 xxl:w-6/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto">
            <h1 class="text-[calc(1.365rem_+_1.38vw)] font-bold leading-[1.2] xl:text-[2.4rem] !mb-3">Terms and Conditions</h1>
            <nav class="inline-block" aria-label="breadcrumb">
              <ol class="breadcrumb flex flex-wrap bg-[none] mb-4 p-0 !rounded-none list-none">
                <li class="breadcrumb-item flex text-[#60697b]"><a class=" text-inherit text-[#60697b]" href="#">Home</a></li>
                <li class="breadcrumb-item active flex text-[#60697b] pl-2 before:font-normal before:flex before:items-center before:text-[rgba(96,105,123,.35)] before:content-['\e931'] before:text-[0.9rem] before:-mt-px before:pr-2 before:font-Unicons" aria-current="page">Terms and Conditions</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
  <div class="card">
    <div class="card-body !p-10">
      <h2 class="!mb-3 !leading-[1.35]">1. Terms and Conditions</h2>
      
      <p>Donec id elit non mi porta gravida at eget metus. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue. Nullam quis risus eget urna mollis ornare vel eu leo. Cras justo odio, dapibus ac facilisis in, egestas eget quam.</p>
      <p>Sed posuere consectetur est at lobortis. Maecenas faucibus mollis interdum. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec id elit non mi porta gravida at eget metus. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>

      <h3 class="text-xl font-semibold mt-8">Photo Contest Guidelines</h3>
      <p>The following terms apply specifically to the photo contest:</p>
      <ul class="pl-0 list-none bullet-bg bullet-soft-primary  !mb-0">
        <li class="relative mt-[0.35rem] flex items-center">
          <span class="pr-[.75rem]">
            <i class="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
          </span>
          <span>All photo submissions must be original and free from any form of plagiarism.</span>
        </li>
        <li class="relative mt-[0.35rem] flex items-center">
          <span class="pr-[.75rem]">
            <i class="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
          </span>
          <span>No explicit or sexual content will be accepted. Please ensure your photos follow the community guidelines.</span>
        </li>
        <li class="relative mt-[0.35rem] flex items-center">
          <span class="pr-[.75rem]">
            <i class="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
          </span>
          <span>Only high-resolution images will be considered for judging. Ensure the photo's quality meets the contest requirements.</span>
        </li>
        <li class="relative mt-[0.35rem] flex items-center">
          <span class="pr-[.75rem]">
            <i class="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
          </span>
          <span>Ensure the content is appropriate and complies with the laws in your region.</span>
        </li>
        <li class="relative mt-[0.35rem] flex items-center">
          <span class="pr-[.75rem]">
            <i class="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
          </span>
          <span>By submitting your photo, you grant us the right to use it for promotional purposes.</span>
        </li>
      </ul>

      <h3 class="text-xl font-semibold mt-8">How to Upload Your Photo</h3>
      <p>Follow the steps below to upload your photo for the contest:</p>
      <ul class="pl-0 list-none bullet-bg bullet-soft-primary  !mb-0">
        <li class="relative mt-[0.35rem] flex items-center">
          <span class="pr-[.75rem]">
            <i class="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
          </span>
          <span>Click on the "Upload Photo" button on the contest page.</span>
        </li>
        <li class="relative mt-[0.35rem] flex items-center">
          <span class="pr-[.75rem]">
            <i class="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
          </span>
          <span>Select your high-resolution image file (JPG, PNG, or GIF format) from your computer or device.</span>
        </li>
        <li class="relative mt-[0.35rem] flex items-center">
          <span class="pr-[.75rem]">
            <i class="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
          </span>
          <span>Ensure the image does not exceed the file size limit of 10MB.</span>
        </li>
        <li class="relative mt-[0.35rem] flex items-center">
          <span class="pr-[.75rem]">
            <i class="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
          </span>
          <span>Click on "Submit" once you have selected the image.</span>
        </li>
        <li class="relative mt-[0.35rem] flex items-center">
          <span class="pr-[.75rem]">
            <i class="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
          </span>
          <span>Your photo will be uploaded for review and eligible for voting once it meets all the guidelines.</span>
        </li>
      </ul>
    </div>
  </div>
</section>

  );
};

export default TermsAndConditions;
