import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import HomePage from './pages/HomePage';
import SignUpPage from './pages/SignUpPage';
import VerifyOtpPage from './pages/VerifyOtpPage';
import ProfilePage from './pages/ProfilePage';
import ContestList from './pages/ContestList';
import ContestDetailPage from './pages/ContestDetailPage';
import LaunchSoonPage from './pages/LaunchSoonPage';
import PhotoUploadForm from './components/PhotoUploadForm';
import LoginPage from './pages/LoginPage';
import OTPVerification from './pages/OTPVerification';
import LogoutPage from './pages/LogoutPage';
import ContactUsPage from './components/ContactUsPage';
import PhotoDetailPage from './pages/PhotoDetailPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import CancellationsAndRefundsPage from './pages/CancellationsAndRefundsPage';
import Not404FoundPage from './pages/Not404FoundPage';
import CompetitionPage from './pages/CompetitionPage';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import MyResume from './components/MyResume';


// Utility function to check authentication
const isAuthenticated = () => {
  return !!localStorage.getItem('access_token'); // Check if access_token exists in localStorage
};

// PrivateRoute component for protected routes
const PrivateRoute = ({ element, ...rest }) => {
  return isAuthenticated() ? element : <Navigate to="/login" />;
};

const App = () => {
  return (
    <Router>
      <div className=''>
      <FloatingWhatsApp
      phoneNumber="+919319965026" // Replace with your WhatsApp number
      accountName="Ewebhub"
      avatar="/assets/img/photos/about27.jpg" // Replace with a URL to the photographer's avatar
      statusMessage="Typically replies within an hour" // Status message for WhatsApp
      chatMessage="Hello! 👋 How can we help you? "
      
      placeholder="Type your message here..." // Placeholder for the input box
      allowClickAway={true}
      notification={true}
      notificationSound={true}
      darkMode={false} // Enable or disable dark mode based on portal theme
    />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/register" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/verify-otp" element={<VerifyOtpPage />} />
          <Route path="/login-verify-otp/:otp_id" element={<OTPVerification />} />
          {/* Protected Routes */}
          <Route path="/launch-soon" element={<LaunchSoonPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/profile" element={<PrivateRoute element={<LaunchSoonPage />} />} />
          <Route path="/contests" element={<ContestList />} />
          <Route path="/photo-contest-detail/:code" element={<ContestDetailPage />} />
          <Route path="/submit-new-entry/:code/upload" element={<PrivateRoute element={<PhotoUploadForm />} />} />
          <Route path="/photo/:photoCode" element={<PhotoDetailPage />} />
          <Route path="/photo-contests" element={<ContestList />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
          <Route path="/cancellation-policies" element={<CancellationsAndRefundsPage />} />
          <Route path="/create-photo-contest" element={<PrivateRoute element={<CompetitionPage />} />} />
          <Route path="/my-resume" element={<MyResume />} />
          <Route path="*" element={<Not404FoundPage />} />
          
          {/* Add other routes here as needed */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
