// sagas/photoListSaga.js

import { call, put, takeLatest } from 'redux-saga/effects';
import httpCommon from '../../services/httpCommon';
import {
  FETCH_PHOTO_LIST_REQUEST,
  fetchPhotoListSuccess,
  fetchPhotoListFailure,
} from '../actions/photoActions';

const fetchPhotoListFromAPI = async (competitionCode) => {
  // Get the access token from localStorage
  let accessToken = localStorage.getItem("access_token");

  // Set headers object
  let headers = {};

  // If token exists, add it to headers
  if (accessToken) {
    headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  // Call the API with or without the Authorization header
  try {
    const response = await httpCommon.get(`/api/photos/?competition_code=${competitionCode}`);

    return response.data;
  } catch (error) {
    console.error("Error fetching photo list:", error);
    // Handle the error accordingly (e.g., display error message to the user)
    return null;
  }
};

function* handleFetchPhotoList(action) {
  try {
    const photos = yield call(fetchPhotoListFromAPI, action.payload);
    yield put(fetchPhotoListSuccess(photos));
  } catch (error) {
    yield put(fetchPhotoListFailure(error.message));
  }
}

export function* photoListSaga() {
  yield takeLatest(FETCH_PHOTO_LIST_REQUEST, handleFetchPhotoList);
}
