import React from 'react';

const PrivacyPolicy = () => {
  return (
    <section id="privacy-policy" className="wrapper p-24">
      <div className="container pt-4 pb-4 !text-center">
        <div className="flex flex-wrap">
          <div className="md:w-10/12 lg:w-8/12 xl:w-7/12 xxl:w-6/12 w-full flex-[0_0_auto] px-[15px] max-w-full !mx-auto">
            <h1 className="text-[calc(1.365rem_+_1.38vw)] font-bold leading-[1.2] xl:text-[2.4rem] !mb-3">
              Privacy Policy
            </h1>
            <nav className="inline-block" aria-label="breadcrumb">
              <ol className="breadcrumb flex flex-wrap bg-[none] mb-4 p-0 !rounded-none list-none">
                <li className="breadcrumb-item flex text-[#60697b]">
                  <a className="text-inherit text-[#60697b]" href="#">
                    Home
                  </a>
                </li>
                <li
                  className="breadcrumb-item active flex text-[#60697b] pl-2 before:font-normal before:flex before:items-center before:text-[rgba(96,105,123,.35)] before:content-['\e931'] before:text-[0.9rem] before:-mt-px before:pr-2 before:font-Unicons"
                  aria-current="page"
                >
                  Privacy Policy
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body !p-10">
          <h2 className="!mb-3 !leading-[1.35]">1. Introduction</h2>
          <p>
            This Privacy Policy outlines the information we collect, how we use it, and how we protect your personal data when you participate in our photo contest. By submitting your photo and personal details, you agree to the terms outlined in this policy.
          </p>

          <h3 className="text-xl font-semibold mt-8">2. Information We Collect</h3>
          <p>We collect the following information when you participate in our photo contest:</p>
          <ul className="pl-0 list-none bullet-bg bullet-soft-primary !mb-0">
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Your full name and contact details (email address).</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Your photo submission (in JPG, PNG, or GIF format).</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Your consent for receiving communications related to the contest.</span>
            </li>
          </ul>

          <h3 className="text-xl font-semibold mt-8">3. How We Use Your Information</h3>
          <p>We use the information we collect for the following purposes:</p>
          <ul className="pl-0 list-none bullet-bg bullet-soft-primary !mb-0">
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>To manage your participation in the photo contest.</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>To communicate with you about your submission, voting, and contest updates.</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>To promote the contest, including using your submitted photos in marketing materials.</span>
            </li>
          </ul>

          <h3 className="text-xl font-semibold mt-8">4. How We Protect Your Information</h3>
          <p>We take reasonable measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction. These measures include:</p>
          <ul className="pl-0 list-none bullet-bg bullet-soft-primary !mb-0">
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Data encryption for sensitive information (e.g., photos, email addresses).</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Secure storage of data on servers that comply with industry standards.</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>Restricted access to authorized personnel only.</span>
            </li>
          </ul>

          <h3 className="text-xl font-semibold mt-8">5. Sharing Your Information</h3>
          <p>We do not sell, trade, or otherwise share your personal information with third parties, except in the following cases:</p>
          <ul className="pl-0 list-none bullet-bg bullet-soft-primary !mb-0">
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>If required by law or in response to legal processes.</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>With service providers who assist in the operation of the contest (e.g., photo judging or email communications).</span>
            </li>
          </ul>

          <h3 className="text-xl font-semibold mt-8">6. Your Rights</h3>
          <p>You have the following rights regarding your personal information:</p>
          <ul className="pl-0 list-none bullet-bg bullet-soft-primary !mb-0">
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>The right to access, correct, or delete your personal data.</span>
            </li>
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-check w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>The right to withdraw consent at any time, without affecting the lawfulness of processing before consent withdrawal.</span>
            </li>
          </ul>

          <h3 className="text-xl font-semibold mt-8">7. Changes to this Policy</h3>
          <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting an updated version of the policy on this page.</p>

          <h3 className="text-xl font-semibold mt-8">8. Contact Us</h3>
          <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
          <ul className="pl-0 list-none bullet-bg bullet-soft-primary !mb-0">
            <li className="relative mt-[0.35rem] flex items-center">
              <span className="pr-[.75rem]">
                <i className="uil uil-envelope w-4 h-4 text-[0.8rem] leading-none tracking-[normal] !text-center flex justify-center items-center bg-[#dce7f9] text-[#3f78e0] rounded-[100%] top-[0.2rem] before:content-['\e9dd'] before:align-middle before:table-cell"></i>
              </span>
              <span>contact@ewebhub.com</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
