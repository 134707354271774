import React from 'react';

const ProjectActivation = () => {
  return (
    <section className="wrapper !bg-[#ffffff]">
      <div className="container !pb-[4.5rem] xl:!pb-24 lg:!pb-24 md:!pb-24">
        
        <div className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] mt-[-30px] mb-10 items-center">
          <div className="xl:w-6/12 lg:w-6/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[30px] max-w-full xl:!order-2 lg:!order-2">
            <ul className="progress-list m-0 p-0 list-none">
              <li className="mb-4">
                <p className="mb-1">Contest Setup</p>
                <div className="progressbar line relative blue" data-value="100">
                  <svg viewBox="0 0 100 6" preserveAspectRatio="none" style={{ display: 'block', width: '100%' }}>
                    <path d="M 0,3 L 100,3" stroke="#eee" strokeWidth="6" fillOpacity="0"></path>
                    <path d="M 0,3 L 100,3" stroke="#555" strokeWidth="6" fillOpacity="0" style={{ strokeDasharray: '100, 100', strokeDashoffset: '0' }}></path>
                  </svg>
                  <div className="progressbar-text" style={{ color: 'inherit', position: 'absolute', right: '0px', top: '-30px' }}>100 %</div>
                </div>
              </li>

              <li className="mb-4">
                <p className="mb-1">Photo Submissions</p>
                <div className="progressbar line relative yellow" data-value="85">
                  <svg viewBox="0 0 100 6" preserveAspectRatio="none" style={{ display: 'block', width: '100%' }}>
                    <path d="M 0,3 L 100,3" stroke="#eee" strokeWidth="6" fillOpacity="0"></path>
                    <path d="M 0,3 L 100,3" stroke="#555" strokeWidth="6" fillOpacity="0" style={{ strokeDasharray: '100, 100', strokeDashoffset: '15' }}></path>
                  </svg>
                  <div className="progressbar-text" style={{ color: 'inherit', position: 'absolute', right: '0px', top: '-30px' }}>85 %</div>
                </div>
              </li>

              <li className="mb-4">
                <p className="mb-1">Voting</p>
                <div className="progressbar line relative orange" data-value="70">
                  <svg viewBox="0 0 100 6" preserveAspectRatio="none" style={{ display: 'block', width: '100%' }}>
                    <path d="M 0,3 L 100,3" stroke="#eee" strokeWidth="6" fillOpacity="0"></path>
                    <path d="M 0,3 L 100,3" stroke="#555" strokeWidth="6" fillOpacity="0" style={{ strokeDasharray: '100, 100', strokeDashoffset: '30' }}></path>
                  </svg>
                  <div className="progressbar-text" style={{ color: 'inherit', position: 'absolute', right: '0px', top: '-30px' }}>70 %</div>
                </div>
              </li>

              <li className="mb-4">
                <p className="mb-1">Prize Distribution</p>
                <div className="progressbar line relative green" data-value="50">
                  <svg viewBox="0 0 100 6" preserveAspectRatio="none" style={{ display: 'block', width: '100%' }}>
                    <path d="M 0,3 L 100,3" stroke="#eee" strokeWidth="6" fillOpacity="0"></path>
                    <path d="M 0,3 L 100,3" stroke="#555" strokeWidth="6" fillOpacity="0" style={{ strokeDasharray: '100, 100', strokeDashoffset: '50' }}></path>
                  </svg>
                  <div className="progressbar-text" style={{ color: 'inherit', position: 'absolute', right: '0px', top: '-30px' }}>50 %</div>
                </div>
              </li>
            </ul>
          </div>

          <div className="xl:w-6/12 lg:w-6/12 w-full flex-[0_0_auto] xl:px-[35px] lg:px-[20px] px-[15px] mt-[30px] max-w-full">
            <h3 className="text-[calc(1.285rem_+_0.42vw)] font-bold xl:text-[1.6rem] !leading-[1.3] mb-5">
              The Photo Contest Portal offers a comprehensive service to engage users and reward creativity.
            </h3>
            <p>
              Our portal allows users to submit their best photos, vote on their favorite entries, and stand a chance to win exciting prizes. Join our growing community of photographers and take part in the most engaging photo contest platform.
            </p>
          </div>
        </div>

        <div className="flex flex-wrap mx-[-15px] xl:mx-[-35px] lg:mx-[-20px] mt-[-30px] xl:mt-0 lg:mt-0 md:mt-0 !text-center">
          <div className="md:w-6/12 lg:w-4/12 xl:w-4/12 w-full flex-[0_0_auto] px-[15px] xl:px-[35px] lg:px-[20px] max-w-full sm:mt-[30px] xsm:mt-[30px]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 409.6 362.5" className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] text-[#3f78e0] text-blue !mb-3 m-[0_auto]">
              <path className="lineal-stroke" d="M343.4 302.9L61 302.5c-25.3-.2-47.5-16.8-54.8-40.9l-3.9-12.9c-7.1-24.2 2.5-50.3 23.5-64.2L262.1 29.8c5.7-3.7 13.3-2.1 17 3.6.7 1 1.2 2.1 1.5 3.2l74.3 249.8c2.4 6.4-.9 13.5-7.3 15.8-1.3.5-2.8.7-4.2.7zM261.8 59.4L39.3 205c-12 7.9-17.4 22.8-13.4 36.6l3.8 12.9c4.2 13.8 16.8 23.3 31.2 23.4l265.8.4-64.9-218.9z"></path>
            </svg>
            <h4>Photo Submissions</h4>
            <p className="!mb-2">
              Submit your creative works and showcase your skills. All types of photography are welcome!
            </p>
          </div>
          <div className="md:w-6/12 lg:w-4/12 xl:w-4/12  w-full flex-[0_0_auto] px-[15px] xl:px-[35px] lg:px-[20px] max-w-full sm:mt-[30px] xsm:mt-[30px]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] text-[#45c4a0] text-green !mb-3 m-[0_auto]">
              <circle className="lineal-fill" cx="283.8" cy="283.8" r="143"></circle>
              <path className="lineal-stroke" d="M283.8 441.8c-87.1 0-158-70.9-158-158s70.9-158 158-158 158 70.9 158 158-70.9 158-158 158zm0-286.1c-70.7 0-128.1 57.3-128.1 128.1S213 411.9 283.8 411.9s128.1-57.3 128.1-128.1c-.1-70.7-57.4-128-128.1-128.1z"></path>
            </svg>
            <h4>Voting</h4>
            <p className="!mb-2">
              Participate in voting and support your favorite photos to help them win the contest!
            </p>
          </div>
          <div className="md:w-6/12 lg:w-4/12 xl:w-4/12  w-full flex-[0_0_auto] px-[15px] xl:px-[35px] lg:px-[20px] max-w-full sm:mt-[30px] xsm:mt-[30px]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inject icon-svg icon-svg-md !w-[2.6rem] !h-[2.6rem] text-[#fab758] text-yellow !mb-3 m-[0_auto]">
              <path className="lineal-fill" d="M186.9 353.4h138.3V413H186.9z"></path>
              <path className="lineal-stroke" d="M460.2 0H51.8C23.2 0 0 23.2 0 51.7v264.4c0 28.6 23.2 51.7 51.8 51.8h120.6v30.7h-28.8c-8 0-14.5 6.5-14.5 14.5s6.5 14.5 14.5 14.5h224.9c8 0 14.5-6.5 14.5-14.5s-6.5-14.5-14.5-14.5h-28.8v-41.9c.3-1.2.4-2.4.4-3.6-.2-8-6.9-14.3-14.8-14.1H51.8C39.2 339 29 328.8 29 316.2V51.7c0-12.6 10.2-22.8 22.8-22.8h408.5c12.6 0 22.8 10.2 22.8 22.8v264.4c0 12.6-10.2 22.8-22.8 22.8h-46.7c-8 .2-14.3 6.9-14.1 14.8.2 8 6.9 14.3 14.8 14.1 48.7-.5 88.7 38.6 89.2 87.3s-38.6 88.7-87.3 89.2h-1.9zM310.7 367.9v30.7H201.3v-30.7h109.4z"></path>
            </svg>
            <h4>Prize Distribution</h4>
            <p className="!mb-2">
              Celebrate the winners and award them with exciting prizes for their creative works.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectActivation;
