import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { FaFacebook, FaTelegram, FaWhatsapp } from "react-icons/fa";
import { fetchPhotoDetailRequest } from "../redux/actions/photoDetailActions";
import { likePhotoRequest } from "../redux/actions/photoActions";
import WinnerPrizeCard from "./WinnerPrizeCard";
import FAQComponent from "./FAQComponent";

const PhotoDetailContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { photoCode } = useParams(); // Retrieve the photo code from the URL
  const { photoDetails, loading, error } = useSelector((state) => state.photoDetail);

  // Track if the current user has liked the photo
  const [userVoted, setUserVoted] = useState(false);

  useEffect(() => {
    if (photoCode) {
      dispatch(fetchPhotoDetailRequest(photoCode)); // Fetch photo details by photoCode
    }
  }, [dispatch, photoCode]);

  // Toggle the like status for the photo
  const toggleLike = () => {
    const accessToken = localStorage.getItem("access_token");

    if (!accessToken) {
      navigate("/login");
    } else {
      const likeValue = userVoted ? 0 : 1; // Toggle between like (1) and unlike (0)

      // Dispatch like/unlike action
      
      dispatch(likePhotoRequest(photoDetails.id, likeValue));

      // Update local state to reflect the change
      setUserVoted(!userVoted);
      window.location.reload();
    }
  };

  const sharePhoto = (platform) => {
    const currentUrl = window.location.href; // Get the current page URL
    const encodedUrl = encodeURIComponent(currentUrl); // Encode the current URL
  
    if (!platform) {
      console.log("Platform is not specified");
      return;
    }
  
    switch (platform) {
      case "facebook":
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`, "_blank");
        break;
      case "telegram":
        window.open(`https://t.me/share/url?url=${encodedUrl}`, "_blank");
        break;
      case "whatsapp":
        window.open(`https://wa.me/?text=${encodedUrl}`, "_blank");
        break;
      case "twitter":
        window.open(`https://twitter.com/intent/tweet?url=${encodedUrl}`, "_blank");
        break;
      default:
        // Handle any invalid platform or missing platform
        console.log("Invalid platform, please select a valid one.");
        break;
    }
  };

  if (loading) return <div className="text-center text-yellow-700">Loading photo details...</div>;
  if (error) return <div className="text-center text-red-500">Error: {error}</div>;

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Photo Section */}
        <img
          src={photoDetails.image}
          alt={photoDetails.title}
          className="w-full h-80 object-cover"
        />

        <div className="p-6">
          {/* Photo Details */}
          <h1 className="text-3xl font-bold text-yellow-700">
            <span className="px-3 py-1 text-sm rounded-full bg-green-500 text-white shadow-md">
              {photoDetails.code}
            </span>
            <span className="text-sm px-4 text-gray-800"><span>Total Votes:</span> <span className="text-lg text-gray-300 
            font-bold  px-2 bg-green-600 mt-2 rounded">{photoDetails.total_votes}</span></span>
          </h1>
          <button
              onClick={toggleLike} // To handle like functionality
              className={`text-4xl px-2 bg-red-300 mt-2 rounded ${photoDetails.user_voted ? "text-red-500 bg-black shadow border-yellow-50" : "text-black"}`}
            >
              ♥
            </button>
          <p className="mt-2 text-lg text-gray-600">{photoDetails.title}</p>
          <p className="mt-2 text-gray-600">{photoDetails.description}</p>

          <div className="mt-6">
            <h3 className="font-semibold text-lg">User Details</h3>
            <p className="text-gray-500">Name: {photoDetails.first_name} {photoDetails.last_name}</p>
            <p className="text-gray-500">Email: {photoDetails.email}</p>
          </div>

          <div className="mt-6 flex justify-between items-center">
            
            <span
              className={`px-3 py-1 text-sm rounded-full ${photoDetails.is_approved ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600"}`}
            >
              {photoDetails.is_approved ? "Approved" : "Not Approved"}
            </span>
          </div>

          {/* Like and Share */}
          <div className="mt-6 flex items-center space-x-4">
            
            <div className="flex space-x-4 shadow-lg rounded-md bg-yellow-300 p-4">
              <button onClick={() => sharePhoto('facebook')} className="text-blue-600">
                <FaFacebook size={24} />
              </button>
              <button onClick={() => sharePhoto('telegram')} className="text-blue-600">
                <FaTelegram size={24} />
              </button>
              <button onClick={() => sharePhoto('whatsapp')} className="text-green-600">
                <FaWhatsapp size={24} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <WinnerPrizeCard />
          {/* FAQ Section */}
          <FAQComponent />
    </div>
  );
};

export default PhotoDetailContent;
