import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // For redirection if not logged in
import httpCommon from "../services/httpCommon";
import Header from "../components/Header";

const ProfilePage = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate for redirection

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem("access_token");

      if (!token) {
        // If there's no token, redirect to login page
        navigate("/login");
        return;
      }

      try {
        // const response = await httpCommon.get("/api/profile", {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // });
        const mockUserProfile = {
          firstName: "John",
          lastName: "Doe",
          email: "john.doe@example.com",
          country: "USA",
          bio: "I am a passionate photographer, exploring the world through my camera lens.",
          coverImage: "https://rstheme.com/products/html/shooter/shooter-html/images/contest/full1.jpg",
          profileImage: "https://rstheme.com/products/html/shooter/shooter-html/images/contest/full1.jpg",
          postsCount: 50,
          followersCount: 200,
          followingCount: 180,
          participatedCompetitions: "Nature Photography Contest, Urban Photography Challenge, Wildlife Photo Contest"
        };

        setUserProfile(mockUserProfile);
        setLoading(false);
      } catch (err) {
        setError("Failed to load profile");
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [navigate]);

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <div className="text-xl text-gray-700">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <div className="text-xl text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-100 py-12">
        <div className="container mx-auto px-6">
          {/* Cover Photo and Profile Image */}
          <div className="relative">
            <img
              src={userProfile.coverImage}
              alt="Cover"
              className="w-full h-64 object-cover rounded-t-lg shadow-lg"
            />
            <div className="absolute bottom-0 left-0 p-6">
              <img
                src={userProfile.profileImage}
                alt="Profile"
                className="w-32 h-32 rounded-full border-4 border-white shadow-lg"
              />
            </div>
          </div>

          {/* User Information */}
          <div className="bg-white p-8 rounded-lg shadow-lg mt-12">
            <h2 className="text-3xl font-semibold text-gray-700">{userProfile.firstName} {userProfile.lastName}</h2>
            <p className="text-lg text-gray-600 mt-2">{userProfile.email}</p>
            <p className="text-lg text-gray-600 mt-2">{userProfile.country}</p>

            {/* Additional user details */}
            <div className="mt-8">
              <h3 className="text-xl font-semibold text-gray-700">About</h3>
              <p className="text-gray-600 mt-4">{userProfile.bio || "No bio available"}</p>
            </div>

            {/* Participated Competitions */}
            <div className="mt-8">
              <h3 className="text-xl font-semibold text-gray-700">Participated Competitions</h3>
              <ul className="list-disc pl-5 mt-4 text-gray-600">
                {userProfile.participatedCompetitions
                  ? userProfile.participatedCompetitions.split(",").map((competition, index) => (
                      <li key={index}>{competition.trim()}</li>
                    ))
                  : "No competitions participated in yet."}
              </ul>
            </div>

            {/* Edit Profile Button */}
            <div className="mt-8 flex justify-end">
              <button
                onClick={() => navigate("/edit-profile")}
                className="px-6 py-2 text-white bg-yellow-600 rounded-lg hover:bg-yellow-700"
              >
                Edit Profile
              </button>
            </div>
          </div>

          {/* Profile Stats (Optional) */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-semibold text-gray-700">Total Photo</h3>
              <p className="text-lg text-gray-600">{userProfile.postsCount}</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-semibold text-gray-700">Partcipated Competition</h3>
              <p className="text-lg text-gray-600">{userProfile.followersCount}</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h3 className="text-2xl font-semibold text-gray-700">Total Vote</h3>
              <p className="text-lg text-gray-600">{userProfile.followingCount}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
