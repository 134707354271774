// redux/actions/photoActions.js
export const UPLOAD_PHOTO_REQUEST = 'UPLOAD_PHOTO_REQUEST';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAILURE = 'UPLOAD_PHOTO_FAILURE';

export const FETCH_PHOTO_LIST_REQUEST = 'FETCH_PHOTO_LIST_REQUEST';
export const FETCH_PHOTO_LIST_SUCCESS = 'FETCH_PHOTO_LIST_SUCCESS';
export const FETCH_PHOTO_LIST_FAILURE = 'FETCH_PHOTO_LIST_FAILURE';

export const LIKE_PHOTO_REQUEST = "LIKE_PHOTO_REQUEST";
export const LIKE_PHOTO_SUCCESS = "LIKE_PHOTO_SUCCESS";
export const LIKE_PHOTO_FAILURE = "LIKE_PHOTO_FAILURE";

// Action Creators
export const uploadPhotoRequest = (data) => ({
  type: UPLOAD_PHOTO_REQUEST,
  payload: data,
});

export const uploadPhotoSuccess = (photoData) => ({
  type: UPLOAD_PHOTO_SUCCESS,
  payload: photoData,
});

export const uploadPhotoFailure = (error) => ({
  type: UPLOAD_PHOTO_FAILURE,
  payload: error,
});



// Action Creators
export const fetchPhotoListRequest = (competitionCode) => ({
  type: FETCH_PHOTO_LIST_REQUEST,
  payload: competitionCode,
});
export const fetchPhotoListSuccess = (photos) => ({
  type: FETCH_PHOTO_LIST_SUCCESS,
  payload: photos,
});
export const fetchPhotoListFailure = (error) => ({
  type: FETCH_PHOTO_LIST_FAILURE,
  payload: error,
});

export const likePhotoRequest = (photoId, likeValue) => ({
  type: LIKE_PHOTO_REQUEST,
  payload: { photoId, likeValue },
});

export const likePhotoSuccess = (photoId, likeValue) => ({
  type: LIKE_PHOTO_SUCCESS,
  payload: { photoId, likeValue },
});

export const likePhotoFailure = (error) => ({
  type: LIKE_PHOTO_FAILURE,
  payload: error,
});
