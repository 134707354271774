import React from "react";

const Projects = () => {
  const projects = [
    {
      title: "Dynamic Chatbot Creation Platform",
      description:
        "A platform to create custom chatbots tailored to specific needs using OpenAI's GPT-3.",
      tools: "FastAPI, LangChain, NLP, React.js, OpenCV",
    },
    {
      title: " Image and NLP Problem Solver",
      description:
        "An application to solve various image and natural language-based problems using machine learning.",
      tools: "Django, Python, Django Rest Framework, React.js, OpenCV, NLP",
    },
    {
      title: "Anonymous Safe Place Platform",
      description:
        "A platform to highlight ideas and provide thought-provoking content.",
      tools: "Django, Python, PostgreSQL, Bootstrap, XML, NumPy, Pandas",
    },
    {
      title: "Three-Way Bridge Between Educational Institutes, Alumni, and Students",
      description:
        "A system to manage alumni networks and facilitate donations and engagements.",
      tools: "Django, NumPy, Pandas, Python, PostgreSQL, Bootstrap",
    },
    {
      title: "Alumni Connect",
      description:
        "A multi-organization management system for alumni networks and donations.",
      tools: "Django, NumPy, Pandas, Python, PostgreSQL, XML, Bootstrap",
    },
    {
      title: "Multistore Product Inventory Management",
      description:
        "A system to manage product inventory store-wise with admin and store owner dashboards.",
      tools: "Django, Python, PostgreSQL, Bootstrap",
    },
    {
      title: "Content Management System",
      description:
        "A platform to manage portfolios, blogs, testimonials, and other content.",
      tools: "Django, Python, PostgreSQL, Bootstrap, Angular 8",
    },
    {
      title: "Project Management and Employee Timesheet",
      description:
        "A system for managing employee tasks and project timesheets.",
      tools: "Django, Python, PostgreSQL, Bootstrap",
    },
    {
      title: "Scraper Tools",
      description:
        "A set of tools for scraping data from websites.",
      tools: "Python, Django, MySQL",
    },
    {
      title: "Marketplace Buyer Seller Communication",
      description:
        "A platform to enable communication between buyers, sellers, and admins.",
      tools: "Python, Django, MySQL, React.js, XML",
    },
    {
      title: "Multivendor Ecommerce Site and Data Scraping Tools",
      description:
        "A multi-vendor eCommerce platform with product management and order processing.",
      tools: "Laravel, PHP, Ajax, jQuery",
    },
    {
      title: "Bitcoin Mining Tools",
      description:
        "Tools to earn free Bitcoin by signing up with a Bitcoin address.",
      tools: "PHP, Ajax, jQuery",
    },
    {
      title: "Multi-Vendor Event Service Provider Portal",
      description:
        "A platform for event service providers to manage events and accept online payments.",
      tools: "CodeIgniter, PHP, Ajax, jQuery",
    },
  ];

  return (
    <section id="projects" className="py-20 bg-cyan-600">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {projects.map((project, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow hover:shadow-lg transition"
            >
              <h3 className="text-xl font-bold mb-2">{project.title}</h3>
              <p className="text-gray-700">{project.description}</p>
              <p className="mt-3 text-gray-500">
                <strong>Tools: </strong> {project.tools}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
